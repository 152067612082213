import { useState, useRef, useEffect } from 'react';
import './planetQuorumCarousel.css';

//ASSETS
import greenCheck from '../../../../../../images/green-check-status.svg';
import Mercury from '../../../../../../images/Mercury.svg';
import Venus from '../../../../../../images/Venus.svg';
import Earth from '../../../../../../images/Earth.svg';
import Moon from '../../../../../../images/Moon.svg';
import Mars from '../../../../../../images/Mars.svg';
import Jupiter from '../../../../../../images/Jupiter.svg';
import Saturn from '../../../../../../images/Saturn.svg';
import Uranus from '../../../../../../images/Uranus.svg';
import Neptune from '../../../../../../images/Neptune.svg';
import Pluto from '../../../../../../images/Pluto.svg';
import PixelVault from '../../../../../../images/PixelVault.svg';
import FoundersDao from '../../../../../../images/FoundersDao.svg';
import { ReactComponent as Thumbsup } from '../../../../../../images/GreenThumbsup.svg';
import { ReactComponent as Thumbsdown } from '../../../../../../images/RedThumbsdown.svg';
import { ReactComponent as AbstainSVG } from '../../../../../../images/abstain.svg';
import { ReactComponent as Circle } from '../../../../../../images/Circle.svg';

interface QuorumProps {
    status: string;
    planetsWeightInfo: any;
    updaoProposalInfo?: any;
    updaoProposalStatus?: any;
    finalVotes?: any;
}

export const PlanetQuorumCarousel: React.FC<QuorumProps> = (props) => {
    const {
        status,
        planetsWeightInfo,
        updaoProposalInfo,
        updaoProposalStatus,
        finalVotes,
    } = props;
    const [currentPlanetIndex, setCurrentPlanetIndex] = useState<number>(0);
    // const [toWin, setToWin] = useState(97);
    const [updaoYes, setUpdaoYes] = useState(0);
    const [updaoNo, setUpdaoNo] = useState(0);
    const [updaoAbstain, setUpdaoAbstain] = useState(0);
    let yesUpdaoTemp = 0;
    let noUpdaoTemp = 0;
    let abstainUpdaoTemp = 0;
    // let totalUpdaoVotes = updaoYes + updaoNo + updaoAbstain;
    const timeoutRef = useRef<any>(null);

    let s = window.location.pathname;
    s = s.substring(0, s.indexOf('proposals'));

    const delay = 10000;

    const mockPlanet = [
        { planetName: 'Mercury', updaoVotes: 5, imgSrc: Mercury },
        { planetName: 'Earth', updaoVotes: 8, imgSrc: Earth },
        { planetName: 'Mars', updaoVotes: 3, imgSrc: Mars },
        { planetName: 'Venus', updaoVotes: 9, imgSrc: Venus },
        { planetName: 'Moon', updaoVotes: 12, imgSrc: Moon },
        { planetName: 'Jupiter', updaoVotes: 20, imgSrc: Jupiter },
        { planetName: 'Saturn', updaoVotes: 17, imgSrc: Saturn },
        { planetName: 'Uranus', updaoVotes: 6, imgSrc: Uranus },
        { planetName: 'Neptune', updaoVotes: 6, imgSrc: Neptune },
        { planetName: 'Pluto', updaoVotes: 1, imgSrc: Pluto },
        { planetName: 'FoundersDAO', updaoVotes: 19, imgSrc: FoundersDao },
        { planetName: 'Pixel Vault', updaoVotes: 87, imgSrc: PixelVault },
    ];

    useEffect(() => {
        getVoteForTotals();
        setUpdaoYes(yesUpdaoTemp);
        setUpdaoNo(noUpdaoTemp);
        //at the moment it seems like we assume pixel vault votes abstain if
        //the proposal is closed and did not meet voting quorum
        status === "Closed"
        ?setUpdaoAbstain(abstainUpdaoTemp+87)
        :setUpdaoAbstain(abstainUpdaoTemp)
    }, [updaoProposalInfo]);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setCurrentPlanetIndex((idx) =>
                    idx === mockPlanet.length - 1 ? 0 : idx + 1
                ),
            delay
        );
        return () => resetTimeout();
    }, [currentPlanetIndex]);

    // Conditions for draft and active being met
    const statusCondition = (planet: string) => {
        return updaoProposalStatus?.[planet];
    };

    const shouldHighlight = (planetName: string, proposalStatus: string) => {
        const planetStat = statusCondition(planetName);
        if (
            planetStat === 'Quorum' ||
            (proposalStatus === 'Draft' && planetStat === 'Active')
        ) {
            return true;
        }
        return false;
    };

    // Check vote for totals
    let planetsYes = (planet: string) => updaoProposalInfo?.[planet]?.['yes'];

    let planetsNo = (planet: string) => updaoProposalInfo?.[planet]?.['no'];

    let planetsAbstain = (planet: string) =>
        updaoProposalInfo?.[planet]?.['abstain'];

    const getVoteForTotals = () => {
        for (let i = 0; i < mockPlanet.length; i++) {
            let planet = mockPlanet[i].planetName;
            let planetsUpdaoVotes = mockPlanet[i].updaoVotes;
            // console.log(planet, ' ', planetsUpdaoVotes, ' ', statusCondition(planet))

            let yesVotes = planetsYes(planet)
            let noVotes = planetsNo(planet)
            let abstainVotes = planetsAbstain(planet)
            // console.log(yesVotes, ' ', noVotes, ' ', abstainVotes)

            if (statusCondition(planet) === 'Quorum') {
                if (
                    yesVotes > noVotes &&
                    yesVotes > abstainVotes
                ) {
                    yesUpdaoTemp += planetsUpdaoVotes;
                } else if (
                    noVotes > yesVotes &&
                    noVotes > abstainVotes
                ) {
                    noUpdaoTemp += planetsUpdaoVotes;
                } else if (
                    abstainVotes > yesVotes &&
                    abstainVotes > noVotes
                ) {
                    abstainUpdaoTemp += planetsUpdaoVotes;
                } else {
                    if (
                        yesVotes > noVotes &&
                        yesVotes === abstainVotes
                    ) {
                        yesUpdaoTemp += planetsUpdaoVotes;
                    }
                    // NO > YES && NO == ABSTAIN
                    else if (
                        noVotes > yesVotes &&
                        noVotes === abstainVotes
                    ) {
                        noUpdaoTemp += planetsUpdaoVotes;
                    }
                    // YES == NO == ABSTAIN
                    else if (
                        yesVotes === noVotes &&
                        yesVotes === abstainVotes
                    ) {
                        noUpdaoTemp += planetsUpdaoVotes;
                    } else {
                        noUpdaoTemp += planetsUpdaoVotes;
                    }
                }
            }
        }
    };

    // get vote for
    const assignVoteFor = (planet: string) => {
        let voteFor;
        const yesVote = planetsYes(planet);
        const noVote = planetsNo(planet);
        const abstainVote = planetsAbstain(planet);
        // console.log(planet,' : ', yesVote, ' ', noVote, ' ', abstainVote)
        if (
            yesVote > noVote &&
            yesVote > abstainVote
        ) {
            voteFor = 'Yes';
        } else if (
            noVote > yesVote &&
            noVote > abstainVote
        ) {
            voteFor = 'No';
        } else if (
            abstainVote > yesVote &&
            abstainVote > noVote
        ) {
            voteFor = 'Abstain';
        } else {
            if (
                yesVote > noVote &&
                yesVote === abstainVote
            ) {
                voteFor = 'Yes';
            }
            // NO > YES && NO == ABSTAIN
            else if (
                noVote > yesVote &&
                noVote === abstainVote
            ) {
                voteFor = 'No';
            }
            // YES == NO == ABSTAIN
            else if (
                yesVote === noVote &&
                yesVote === abstainVote
            ) {
                voteFor = 'No';
            } else {
                voteFor = 'No';
            }
        }
        return voteFor;
    };

    // Draft vs Active values
    let pv = '0/1';

    const planetsDraft = (planet: string) => {
        return `${updaoProposalInfo?.[planet]?.['total']}/${
            planetsWeightInfo?.['updao']?.[`${planet}`]?.['activate']
        }`;
    };

    const planetsActive = (planet: string) => {
        return `${updaoProposalInfo?.[planet]?.['total']}/${
            planetsWeightInfo?.['updao']?.[`${planet}`]?.['votingQuorum']
        }`;
    };

    // Conditions for img/icons
    const draftImg = (planet: string) => {
        return statusCondition(planet) === 'Draft' ||
            planet === 'Pixel Vault' ? (
            <Circle className="grey-circle" fill={'rgba(112, 112, 124, 0.2)'} />
        ) : (
            <img src={greenCheck} alt="green check mark" />
        );
    };

    const activeImg = (planet: string, voteFor: string) => {
        return statusCondition(planet) === 'Quorum' ? (
            renderImg(voteFor)
        ) : (
            <AbstainSVG
                className="spc-image-sm"
                fill="rgba(112, 112, 124, 0.2)"
            />
        );
    };

    // Switches
    const planetStatusSwitch = (name: string) =>
        status === 'Draft' ? planetsDraft(name) : planetsActive(name);

    const renderColor = (state: string) => {
        switch (state) {
            case 'Closed':
                return '#DD3434';
            case 'Active':
                return '#42DD34';
            case 'Quorum':
                return '#0EA8FF';
            default:
                return '#F8B83C';
        }
    };

    const renderImg = (voteFor: string) => {
        switch (voteFor) {
            case 'Yes':
                return <Thumbsup className="spc-image-sm" />;
            case 'No':
                return <Thumbsdown className="spc-image-sm" />;
            case 'Abstain':
                return <AbstainSVG className="spc-image-sm" fill="#F8B83C" />;
            default:
                return;
        }
    };

    const renderSwitch = (name: string) => {
        switch (name) {
            case 'Pixel Vault':
                return pv;
            default:
                return planetStatusSwitch(name);
        }
    };

    const renderText = (proposalStatus: string, planetStatus: string) => {
        if (proposalStatus === 'Draft' && planetStatus !== 'Draft') {
            return 'Activation Quorum Met';
        } else if (proposalStatus !== 'Draft' && planetStatus === 'Active') {
            return 'Voting Quorum';
        } else if (proposalStatus !== 'Draft' && planetStatus === 'Quorum') {
            return 'Voting Quorum Met';
        } else {
            return 'Activation Quorum';
        }
    };

    const renderPVText = (proposalStatus: string) => {
        if (proposalStatus === 'Draft') {
            return 'Activation Quorum';
        } else {
            return 'Voting Quorum';
        }
    };

    const renderUpdaoInfoText = (status: string) => {
        switch (status) {
            case 'Draft':
                return 'All entities, except Pixel Vault, must reach at least 1% active quorum in order for this proposal to become activated or the proposal is otherwise rejected. ';
            case 'Active':
            case 'Quorum':
            case 'Closed':
                return 'All entities, except Pixel Vault, must reach at least 10% voting quorum or the proposal is otherwise rejected. If voting quorum is reached then the majority vote towards Yes or No will apply. ';
            default:
                return '';
        }
    };

    return (
        <div className="quorum-planets-container">
            <div id="quorum-status">
                <div className="quorum-status-title">
                    <h3>Quorum Status</h3>
                    <div className="quorum-status-light">
                        <Circle
                            style={{ paddingRight: '5%' }}
                            fill={renderColor(status)}
                        />
                        <div>{status}</div>
                    </div>
                </div>

                <div className="quorum-status-text">
                    {renderUpdaoInfoText(status)}
                    <a href={`${s}planetary-ecosystem#voting-sec`}>
                        Learn more
                    </a>
                </div>

                {status !== 'Draft' ? (
                    <div className="planet-results">
                        <div className="votes">
                            <div>
                                <Thumbsup className="spc-image" />
                                {status === 'Closed' && finalVotes
                                    ? finalVotes?.['yes']
                                    : updaoYes}
                            </div>

                            <div>
                                <AbstainSVG
                                    className="spc-image"
                                    fill="#F8B83C"
                                />
                                {status === 'Closed' && finalVotes
                                    ? finalVotes?.['abstain']
                                    : updaoAbstain}
                            </div>
                            <div>
                                <Thumbsdown className="spc-image" />
                                {status === 'Closed' && finalVotes
                                    ? finalVotes?.['no']
                                    : updaoNo}
                            </div>
                        </div>
                        <span>
                            {status !== 'Closed' ? 'Current ' : null} UPDAO
                            vote(s)
                        </span>
                    </div>
                ) : null}
                <ul className="quorum-poll">
                    {mockPlanet.map((planet, index) => (
                        <li
                            onClick={() => setCurrentPlanetIndex(index)}
                            id={planet.planetName}
                            className={
                                index === currentPlanetIndex
                                    ? 'quorum-planet-status active'
                                    : 'quorum-planet-status'
                            }
                            key={index}
                        >
                            <span>
                                {/* status is closed then pv abstains always? */}
                                {status === 'Closed' &&
                                planet.planetName === 'Pixel Vault' ? (
                                    <AbstainSVG
                                        className="spc-image-sm"
                                        fill="#F8B83C"
                                    />
                                ) : status === 'Draft' ? (
                                    draftImg(planet.planetName)
                                ) : (
                                    activeImg(
                                        planet.planetName,
                                        assignVoteFor(planet.planetName)
                                    )
                                )}

                                {planet.planetName === 'FoundersDAO'
                                    ? "Founder's..."
                                    : planet.planetName}
                            </span>
                            <span
                                style={
                                    status === 'Closed' &&
                                    planet.planetName === 'Pixel Vault'
                                        ? { color: '#fff' }
                                        : shouldHighlight(
                                              planet.planetName,
                                              status
                                          )
                                        ? { color: '#fff' }
                                        : { color: 'rgba(156, 156, 169, 0.74)' }
                                }
                            >
                                {status === 'Draft'
                                    ? renderSwitch(planet.planetName)
                                    : planet.updaoVotes}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="carousel">
                <div
                    className="carouselSlider"
                    style={{
                        transform: `translate3d(${
                            -currentPlanetIndex * 100
                        }%, 0, 0)`,
                    }}
                >
                    {mockPlanet.map((planet, index) => (
                        <div key={index} className="slide">
                            <div className="slide-header">
                                <img
                                    src={planet.imgSrc}
                                    alt="planet image"
                                    className="planet-quorum-img"
                                />
                                <div className="header-flex">
                                    <h3>
                                        {planet.planetName === 'FoundersDAO'
                                            ? "Founder's DAO"
                                            : planet.planetName}
                                    </h3>
                                    <span>{planet.updaoVotes} UPDAO Votes</span>
                                </div>
                            </div>
                            <div className="slide-progress-container">
                                <div>
                                    <Thumbsup className="spc-image" />
                                    {planet.planetName === 'Pixel Vault'
                                        ? 0
                                        : updaoProposalInfo?.[
                                              planet.planetName
                                          ]?.['yes'].toLocaleString('en-US')}
                                </div>
                                <div>
                                    <AbstainSVG
                                        className="spc-image vertical-align-text-top"
                                        fill="#F8B83C"
                                    />
                                    {planet.planetName === 'Pixel Vault' &&
                                    status === 'Closed'
                                        ? 87
                                        : updaoProposalInfo?.[
                                              planet.planetName
                                          ]?.['abstain'].toLocaleString(
                                              'en-US'
                                          )}
                                </div>
                                <div>
                                    <Thumbsdown className="spc-image vertical-align-text-top" />
                                    {planet.planetName === 'Pixel Vault'
                                        ? 0
                                        : updaoProposalInfo?.[
                                              planet.planetName
                                          ]?.['no'].toLocaleString('en-US')}
                                </div>
                            </div>
                            <div className="slide-quorum-info">
                                <div>
                                    <span>
                                        {((statusCondition(
                                            planet.planetName
                                        ) !== 'Draft' &&
                                            status === 'Draft') ||
                                            (statusCondition(
                                                planet.planetName
                                            ) === 'Quorum' &&
                                                status !== 'Draft')) &&
                                        planet.planetName !== 'Pixel Vault' ? (
                                            <>
                                                <img
                                                    src={greenCheck}
                                                    alt="green check mark"
                                                />{' '}
                                            </>
                                        ) : (
                                            <>
                                                <Circle
                                                    className="grey-circle"
                                                    fill={
                                                        'rgba(112, 112, 124, 0.2)'
                                                    }
                                                />
                                            </>
                                        )}
                                        {planet.planetName === 'Pixel Vault'
                                            ? renderPVText(status)
                                            : renderText(
                                                  status,
                                                  statusCondition(
                                                      planet.planetName
                                                  )
                                              )}
                                    </span>
                                    <span>
                                        {renderSwitch(planet.planetName)}
                                    </span>
                                </div>

                                {/* <span>
                                    {status === 'Draft'
                                        ? `${updaoProposalInfo?.[
                                              planet.planetName
                                          ]?.['total'].toLocaleString(
                                              'en-US'
                                          )} Votes`
                                        : `${planet.updaoVotes} UPDAO Votes`}
                                </span> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
