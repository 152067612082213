import React, { useEffect, useState } from "react";
import "./proposalsPageCommentBox.css";
import useData from "../../../../../utils/dataContext";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { generateSignedPayload } from "../../../../../utils/functions";
import love from "../../../../../images/love.svg";
import filledLove from "../../../../../images/fillLove.svg";
import axiosInstance from "../../../../../axiosHelper/axios";
import { truncate } from "../../../../../utils/functions";

type PageCommentBoxProps = {
    creatorAddress: any;
    creatorImg: string;
    createdAt: string; // string, might change to Date later
    comment: string;
    likesTotal: number;
    likedBy: string[];
    commentId: string;
};

const ProposalsPageCommentBox: React.FC<PageCommentBoxProps> = (props) => {
    const {
        creatorAddress,
        creatorImg,
        createdAt,
        comment,
        likesTotal,
        likedBy,
        commentId,
    } = props;
    const { updaoAccount } = useData();
    const { active, library } = useWeb3React<Web3Provider>();
    const [didLike, setDidLike] = useState<boolean>(false);
    const [currLikes, setCurrLikes] = useState<number>(likesTotal);
    const [ensProfileUrl, setEnsProfileUrl] = useState<string>('');

    let provider = active ? library?.provider : undefined;

    useEffect(() => {
        const getEnsProfile = async (walletAddress: string) => {
            await axiosInstance
                .get(`/accounts/ens/?address=${creatorAddress}`)
                .then((res) => {
                    if(res.data){
                        let ensUrl = res.data.ensInfo.ensInfo.avatar.linkage[7].content
                        setEnsProfileUrl(ensUrl)
                    }
                })
                .catch((err) => {
                    console.log(`No ens for proposal creator: ${err}`)
                })
        }
        if(updaoAccount && ensProfileUrl === ''){
            getEnsProfile(updaoAccount)
          }
        if (likedBy && likedBy.includes(updaoAccount?.["_id"])) {
            setDidLike(true);
        }
    }, []);

    //helps resets # of likes after applying a filter
    useEffect(() => {
        setCurrLikes(likesTotal)
    }, [likesTotal])

    const handleLikeClick = async () => {
        try {
            if (updaoAccount && !didLike) {
                let response, signature;

                let msgBodyLike = {
                    accountId: updaoAccount?.["_id"],
                    signerAddress: updaoAccount?.address,
                };

                signature = await generateSignedPayload(provider, msgBodyLike);
                response = await postLike(signature, msgBodyLike);

                if (response) {
                    console.log("succcess");
                    setDidLike(true);
                    setCurrLikes(currLikes + 1);
                } else {
                    console.log("error");
                    setDidLike(false);
                    setCurrLikes(currLikes);
                }
            }
        } catch (error: any) {
            console.log(error);
        }
    };

    const postLike = async (signature: any, body: any = {}) => {
        const res = await axiosInstance.post(`comment/like/${commentId}`, {
            msgBody: body,
            signature: signature,
        });
        return res;
    };

    // const CollapsibleParagraphProps = {
    //     lines: 5,
    //     locales: { expand: "read more", collapse: "show less" },
    // };

    const nudgeButton = () => {
        if (!updaoAccount) {
            setDidLike(false);

            document
                .querySelector(".dialog-container .button")
                ?.classList.add("nudge-btn");
        }
        setTimeout(() => {
            document
                .querySelector(".dialog-container .button")
                ?.classList.remove("nudge-btn");
        }, 3000);
    };

    return (
        <>
            <div className="comments-card">
                <img
                    className="comment-image"
                    src={ensProfileUrl !== ''?ensProfileUrl:creatorImg}
                    alt="profile"
                    width={40}
                    height={40}
                />
                <div className="flex-container-c">
                    <div className="flex-item-left-c">
                        <div className="details-container-c">
                            <span
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        creatorAddress
                                    );
                                }}
                                className="address-c tooltip2"
                            >
                                <div className="tooltip2">
                                    <span className="tooltip-text2">copy</span>
                                    <span className="address-c-text">
                                        {truncate(creatorAddress)}
                                    </span>
                                </div>
                                {/* <span className="tooltip-text2">copy</span> */}
                                {/*                             
                                {creatorAddress} */}
                            </span>
                            <span className="other-span-c">{createdAt}</span>
                        </div>
                    </div>
                    <div className="flex-item-right-c">
                        <div className="details-container-c">
                            <img
                                aria-disabled={didLike}
                                onClick={() => {
                                    handleLikeClick();
                                    nudgeButton();
                                }}
                                className="comm-img-c"
                                src={didLike ? filledLove : love}
                                alt="profile"
                                width={16}
                                height={16}
                            />

                            <span
                                style={
                                    didLike
                                        ? { color: "#fff" }
                                        : { color: "rgba(156, 156, 169, 0.74)" }
                                }
                                className="likes"
                            >
                                {currLikes}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="comment">
                    <span className="comment-text">
                        {/* <Collapsible {...CollapsibleParagraphProps}> */}
                            {comment}
                        {/* </Collapsible> */}
                    </span>
                </div>
            </div>
        </>
    );
};

export default ProposalsPageCommentBox;
