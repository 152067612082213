import { ethers } from "ethers";
import { parseISO, formatDistanceToNow } from "date-fns";

// const PLANETS_MAP = {
//   "Mercury": 0,
//   "Venus": 1,
//   "Earth": 2,
//   "Moon": 10,
//   "Mars": 4,
//   "Jupiter": 5,
//   "Saturn": 6,
//   "Uranus": 7,
//   "Neptune": 8,
//   "Pluto": 9
// }

export const truncate = (account: string | null | undefined) => {
  if (account !== undefined && account !== null) {
    return account.slice(0, 6) + "..." + account.slice(-4);
  } else {
    return "";
  }
};

export const truncateText = (text: string, charLimit: number) => {
  if(text === undefined){
    return "not found"
  }
  if(text.length >= charLimit){
    return text.slice(0, charLimit) + "...";
  } else {
    return text
  }
};

export const daysAgo = (createdDate: string ) => {
  const formatted = parseISO(createdDate)
  if(createdDate === "" || createdDate == null){
    return "no date"
  } else {
    const subDate = formatDistanceToNow(formatted, { addSuffix: true })
    return subDate    
  }
};

export const removeSpaces = (str: string) => {
  return str.replace(/\s/g, '');
}

export const getTimeRelativeToNow = (date: string | undefined) => {
  if (!!date) {
      const formatted = parseISO(date);
      const subDate = formatDistanceToNow(formatted, {
          addSuffix: true,
      });
      return subDate;
  } else {
      return "no date";
  }
};

export const getPlanetIdFromName = (planetName: string) => {
  switch(planetName) {
    case "Mercury":
      return 0;
    case "Venus":
      return 1;
    case "Earth":
        return 2;
    case "Moon":
      return 10;
    case "Mars":
      return 4;
    case "Jupiter":
      return 5;
    case "Saturn":
      return 6;
    case "Uranus":
        return 7;
    case "Neptune":
      return 8;
    case "Pluto":
      return 9;
    default:
      return -1;   
  } 
}

export const generateSignedPayload = async (    
  provider: any,
  payload: any
  ) => {
    const ethersProvider = new ethers.providers.Web3Provider(provider, "any");
    const signer = ethersProvider?.getSigner();
    const signature = await signer?.signMessage(JSON.stringify(payload));
    return signature;
}
