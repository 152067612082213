import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ethers } from 'ethers';
import { recoverAddress, arrayify, hashMessage } from 'ethers/lib/utils';

import { PlanetImage } from './planetImage';
import pfp from '../../images/pfp.svg';
import profile from '../../images/profile.svg';
import open from '../../images/open.svg';
import closed from '../../images/closed.svg';
import planetarrowdown from '../../images/planet-arrowdown.svg';
import useData from '../../utils/dataContext';
import { useWeb3React } from '@web3-react/core';
import { ConnectButtonSecond } from '../connectButtonComponent/connectButtonSecond';
import SidePopUp from '../SidePopUp/SidePopUp';

import './headerComponent.css';
import axiosInstance from '../../axiosHelper/axios';
// import { sign } from 'crypto';

export const getAccount = async (walletAddress: string) => {
    const updaoAccount = await axiosInstance
        .get(`/accounts/address/?address=${walletAddress}`)
        .then((res) => {
            return res.data;
        });
    return updaoAccount;
};

export const userHasAccount = async (walletAddress: string) => {
    const accountResponse = await getAccount(walletAddress);
    return accountResponse.length === 0 ? false : true;
};

// export const createAccount = async (walletAddress: string) => {
//   const result = await axiosInstance.post('/accounts/create', {
//     name: walletAddress,
//     address: walletAddress,
//     ens: '',
//     bio: '',
//     pfpUrl: '',
//     holdings: {},
//   }).then((res) => {
//     return res.data
//   }).catch((err) => {
//     console.log(err);
//   })
// }

const Header: React.FC = () => {
    const [expandMainNav, setExpandMainNav] = useState(false);
    const [hasUpdaoAccount, setHasUpdaoAccount] = useState(false);
    const [accountCreate, setAccountCreateSignature] = useState<string>('');
    const [ensProfileUrl, setEnsProfileUrl] = useState<string>('');

    const {
        planetsNames,
        // walletAddress,
        planetDropDown,
        setPlanetDropDown,
        // updaoAccount,
        setUpdaoAccount,
        setAllPlanetBalance,
        setFounderDAOBalance,
        walletSus
    } = useData();

    const { active, library, account, deactivate } =
        useWeb3React();

    const truncate = (account: any) => {
        return account.slice(0, 6) + '...' + account.slice(-4);
    };

    const location = useLocation();

    useEffect(() => {
        const getEnsProfile = async (walletAddress: string) => {
            await axiosInstance
                .get(`/accounts/ens/?address=${walletAddress}`)
                .then((res) => {
                    if(res.data){
                        let ensUrl = res.data.ensInfo.ensInfo.avatar.linkage[7].content
                        setEnsProfileUrl(ensUrl)
                    }
                })
                .catch((err) => {
                    console.log(`Could not find ens profile: ${err}`)
                })
        }
        const getAccount = async (walletAddress: string) => {
            const updaoAccount = await axiosInstance
                .get(`/accounts/address/?address=${walletAddress}`)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
            // set context
            return updaoAccount;
        };

        const signMessage = async (
            provider: any,
            walletAddress: any,
            messageToSign: string
        ) => {
            // console.log(messageToSign);
            const ethersProvider = new ethers.providers.Web3Provider(
                provider,
                'any'
            );
            const signer = ethersProvider?.getSigner();
            const addressToVerify = await signer?.getAddress();
            const signature = await signer?.signMessage(
                JSON.stringify(messageToSign)
            );
            const verifySigningAddress = recoverAddress(
                arrayify(hashMessage(JSON.stringify(messageToSign))),
                signature
            );
            if (verifySigningAddress !== walletAddress) {
                console.error(
                    'Voting fails to sign. Our verified address is not the same as the address provided for signing'
                );
            } else {
                setAccountCreateSignature(signature);
            }
            return signature;
        };

        const signMessage2 = async (
            provider: any,
            walletAddress: any,
            messageToSign: object
        ) => {
            const ethersProvider = new ethers.providers.Web3Provider(
                provider,
                'any'
            );
            const signer = ethersProvider?.getSigner();
            const addressToVerify = await signer?.getAddress();
            const signature = await signer?.signMessage(
                JSON.stringify(messageToSign)
            );
            // console.log(`SIGNATURE 2 - ${signature}`);

            return signature;
        };

        const haveSignatures = true; // flip to true when signatures has been merged
        const createURL = haveSignatures
            ? '/accounts/create'
            : '/accounts/create_no_signature/';

        const createAccount = async (msgBody: Object, signature: string) => {
            const result = await axiosInstance
                .post(createURL, { msgBody: msgBody, signature })
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
            // set context
            return result;
        };

        let provider = active ? library?.provider : undefined;

        const checkAccountAndCreateIfNone = async (walletAddress: string) => {
            const existingUpdaoAccountArray = await getAccount(walletAddress);
            const existingUpdaoAccount = existingUpdaoAccountArray.length ? existingUpdaoAccountArray[0] : existingUpdaoAccountArray;

            if (existingUpdaoAccount && existingUpdaoAccount._id) {
                setUpdaoAccount(existingUpdaoAccount);
                setHasUpdaoAccount(true);
            } else if (
                !!existingUpdaoAccount ||
                existingUpdaoAccount.length === 0
            ) {
                const msgBody = {
                    name: walletAddress,
                    address: walletAddress,
                    signerAddress: walletAddress,
                    ens: '',
                    bio: '',
                    pfpURL: '',
                };

                const signature = await signMessage2(
                    provider,
                    walletAddress,
                    msgBody
                );
                const newUpdaoAccount = await createAccount(msgBody, signature);
                setUpdaoAccount(newUpdaoAccount.account);
                setHasUpdaoAccount(true);
            }
        };
        if (account && !walletSus &&!hasUpdaoAccount) {
            checkAccountAndCreateIfNone(account);
        }
        if(account && ensProfileUrl === ''){
          getEnsProfile(account)
        }
    }, [account]);

    const closeMenu = () => {
        setExpandMainNav(false);
    };

    async function deactivated() {
        try {
            deactivate();
            setAllPlanetBalance([]);
            setFounderDAOBalance();
            setUpdaoAccount();
            if (localStorage?.getItem('isMetamaskConnected') === 'true') {
                localStorage.setItem('isMetamaskConnected', String(false));
            }
            if (localStorage?.getItem('isCoinbaseConnected') === 'true') {
                localStorage.setItem('isCoinbaseConnected', String(false));
            }
            if (localStorage?.getItem('isConnectConnected') === 'true') {
                localStorage.setItem('isConnectConnected', String(false));
            }
            window.location.reload();
        } catch (ex) {
            console.log(ex);
        }
    }

    return (
        <>
            <div className="top-navbar"></div>

            {/* main navbar */}
            <div
                className="main-navbar"
                // className={expandMainNav ? "main-navbar expanded" : "main-navbar"}
            >
                {/* NETWORK POPUP */}
                {!active ? null : <SidePopUp />}
                {/* LOGO */}
                <div
                    className="logo"
                    onClick={() => setPlanetDropDown(!planetDropDown)}
                >
                    <div className="logo-image">
                        {location.pathname.includes(
                            `/proposals/${planetsNames}`
                        ) ||
                        location.pathname.includes(
                            `/treasury/${planetsNames}`
                        ) ? (
                            <PlanetImage />
                        ) : null}
                    </div>
                    <div className="logo-text">
                        {location.pathname.includes(
                            `/proposals/${planetsNames}`
                        ) ||
                        location.pathname.includes(`/treasury/${planetsNames}`)
                            ? planetsNames
                            : 'PRISM'}
                    </div>

                    {location.pathname === `/proposals/${planetsNames}` ||
                    location.pathname === '/proposals' ? (
                        // || location.pathname === "/treasury" ||
                        // location.pathname === `/treasury/${planetsNames}`
                        <div className="planet-arrowdown">
                            <img src={planetarrowdown} alt="arrow down" />
                        </div>
                    ) : null}
                </div>

                {/* NAVIGATION MENU */}
                <ul
                    className={
                        expandMainNav
                            ? 'mainnav-links expanded'
                            : 'mainnav-links'
                    }
                >
                    {/* NAVIGATION MENU */}
                    <div
                        className={
                            expandMainNav ? 'main-menu expanded' : 'main-menu'
                        }
                    >
                        <li
                            onClick={() => (expandMainNav ? closeMenu() : null)}
                        >
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? 'active-nav' : 'default-menu'
                                }
                                to="/"
                            >
                                Overview
                            </NavLink>
                        </li>
                        <li
                            onClick={() => (expandMainNav ? closeMenu() : null)}
                        >
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ||
                                    location.pathname ===
                                        `/proposals/${planetsNames}`
                                        ? 'active-nav'
                                        : 'default-menu'
                                }
                                to="/proposals/UPDAO"
                            >
                                Proposals
                            </NavLink>
                        </li>
                        <li
                            onClick={() => (expandMainNav ? closeMenu() : null)}
                        >
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? 'active-nav' : 'default-menu'
                                }
                                to="/treasury"
                            >
                                Treasury
                            </NavLink>
                        </li>
                        {/* <li>
              <NavLink
                className="disabled-link"
                style={({ isActive }) =>
                  isActive
                    ? {
                        color: "#fff",
                        textDecoration: "underline",
                        textUnderlineOffset: "70%",
                      }
                    : { color: "#9c9ca9bd" }
                }
                to="/pow"
              >
                $POW
              </NavLink>
            </li> */}
                        <li
                            onClick={() => (expandMainNav ? closeMenu() : null)}
                        >
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? 'active-nav' : 'default-menu'
                                }
                                to="/planetary-ecosystem"
                            >
                                Planetary Ecosystem
                            </NavLink>
                        </li>
                        <li>
                            {expandMainNav ? (
                                !active ? (
                                    <ConnectButtonSecond
                                        widgetPadding={false}
                                    />
                                ) : (
                                    <div className="walletdetails">
                                        <img
                                            className="prop-img"
                                            src={profile}
                                            alt="profile"
                                        />
                                        <div>
                                            <span className="walletaddress">
                                                {truncate(account)}
                                            </span>
                                            <div className="details">
                                                <span onClick={deactivated}>
                                                    Disconnect
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ) : null}
                        </li>
                    </div>
                </ul>

                <div
                    className={
                        expandMainNav
                            ? 'overview-connect expanded'
                            : 'overview-connect'
                    }
                >
                    {
                        //  !walletAddress ?
                        // <ConnectButton widgetPadding={false} /> : null
                        !active ? (
                            <ConnectButtonSecond widgetPadding={false} />
                        ) : (
                            <span className="nav-active">
                                <div>
                                    <span>{truncate(account)}</span>
                                    <div
                                        style={{
                                            float: 'right',
                                            marginTop: '-10px',
                                        }}
                                        className="details"
                                    >
                                        <span onClick={deactivated}>
                                            Disconnect
                                        </span>
                                    </div>
                                </div>

                                <img
                                    style={{ paddingLeft: '5px' }}
                                    alt="Profile"
                                    src={ensProfileUrl !== ''?ensProfileUrl:pfp}
                                    width="18"
                                    height="18"
                                />
                            </span>
                        )
                    }
                </div>

                <div className="hamburger">
                    <span
                        className="mobile-button"
                        onClick={() => {
                            setExpandMainNav(!expandMainNav);
                        }}
                    >
                        <img
                            className={
                                expandMainNav
                                    ? 'hamburger expanded'
                                    : 'hamburger'
                            }
                            src={expandMainNav ? open : closed}
                            height={40}
                            width={40}
                            alt="hamburger"
                        />
                    </span>
                </div>
            </div>
        </>
    );
};

export default Header;
