import { useEffect, useRef, useState } from 'react';
import useData from '../../../utils/dataContext';
import './planetCarousel.css';
import updao from '../../../images/pvlogo.svg';
import mercury from '../../../images/Mercury.svg';
import venus from '../../../images/venuslight.svg';
import earth from '../../../images/Earth.svg';
import moon from '../../../images/Moon.svg';
import mars from '../../../images/marslight.svg';
import jupiter from '../../../images/jupiterlight.svg';
import saturn from '../../../images/saturnlight.svg';
import uranus from '../../../images/Uranus.svg';
import neptune from '../../../images/Neptune.svg';
import pluto from '../../../images/Pluto.svg';
import foundersDaoBig from '../../../images/FoundersDaoBig.webp';
import arrowRight from '../../../images/arrow-right.svg';
import arrowLeft from '../../../images/arrow-left.svg';

const planetNames = [
    'Mercury',
    'Venus',
    'Earth',
    'Moon',
    'Mars',
    'Jupiter',
    'Saturn',
    'Uranus',
    'Neptune',
    'Pluto',
    'FoundersDAO',
    'PixelVault',
];

const updaoVotes = [5, 9, 8, 12, 3, 20, 17, 6, 6, 1, 19, 87];

export const PlanetCarousel: React.FC = () => {
    const [arrowSwitch, setArrowSwitch] = useState(0);
    const [backSwitch, setBackSwitch] = useState(false);
    const graph = useRef(null);
    const { allPlanetWeights } = useData();

    useEffect(() => {
        const planetgraph: any = graph.current;
        const circleElements = planetgraph.childNodes;

        let circleangle = 360 - 90;
        let circledangle = 360 / circleElements.length;

        for (let i = 0; i < circleElements.length; i++) {
            let circle = circleElements[i];
            circleangle += circledangle;
            circle.style.transform = `rotate(${circleangle}deg) translate(${
                planetgraph.clientWidth / 2
            }px) rotate(-${circleangle}deg)`;
        }

        if (arrowSwitch < 0) {
            setArrowSwitch(11);
            let planetDivOrig = circleElements[0];
            planetDivOrig.style.border = '';
        } else if (arrowSwitch >= 0 && arrowSwitch <= 11) {
            let planetDiv = circleElements[arrowSwitch];

            if (arrowSwitch >= 1) {
                let planetDiv2 = circleElements[arrowSwitch - 1];
                planetDiv2.style.border = '';
            }

            if (backSwitch && arrowSwitch >= 0 && arrowSwitch < 11) {
                let planetDiv3 = circleElements[arrowSwitch + 1];
                planetDiv3.style.border = '';
            }

            planetDiv.style.border = `2px solid white`;
        } else {
            let planetDivEleven = circleElements[11];
            planetDivEleven.style.border = '';
            setArrowSwitch(0);
        }
        
        // const timer = setInterval(() => {
        //     setArrowSwitch(arrowSwitch + 1);
        // }, 5000);
        // return () => {
        //     clearInterval(timer);
        // };

    }, [arrowSwitch]);

    return (
        <div className="planet-carousel">
            <div className="ciclegraph" ref={graph}>
                <div className="circle">
                    <img src={mercury} alt={mercury} />
                </div>
                <div className="circle">
                    <img src={venus} alt={venus} />
                </div>
                <div className="circle">
                    <img src={earth} alt={earth} />
                </div>
                <div className="circle">
                    <img src={moon} alt={moon} />
                </div>
                <div className="circle">
                    <img src={mars} alt={mars} />
                </div>
                <div className="circle">
                    <img src={jupiter} alt={jupiter} />
                </div>
                <div className="circle">
                    <img src={saturn} alt={saturn} />
                </div>
                <div className="circle">
                    <img src={uranus} alt={uranus} />
                </div>
                <div className="circle">
                    <img src={neptune} alt={neptune} />
                </div>
                <div className="circle">
                    <img src={pluto} alt={pluto} />
                </div>
                <div className="circle">
                    <img src={foundersDaoBig} alt={foundersDaoBig} />
                </div>
                <div className="circle">
                    <img src={updao} alt={updao} />
                </div>
            </div>

            <div className="planet-details-carousel">
                <img
                    src={arrowLeft}
                    className="carousel-arrow"
                    onClick={() => {
                        setArrowSwitch(arrowSwitch - 1);
                        setBackSwitch(true);
                    }}
                    alt="left arrow"
                />
                <div className="pc-details">
                    <h3>
                        {planetNames[arrowSwitch] === 'FoundersDAO'
                            ? "Founder's DAO"
                            : planetNames[arrowSwitch] === 'PixelVault'
                            ? 'Pixel Vault'
                            : planetNames[arrowSwitch]}
                    </h3>
                    <span>
                        <span className="label">
                            {
                                // @ts-ignore
                                [planetNames[arrowSwitch]] === 'PixelVault'
                                    ? ''
                                    : 'Tokens:'
                            }
                        </span>{' '}
                        {
                            // @ts-ignore
                            [planetNames[arrowSwitch]] === 'FoundersDAO'
                                ? 5902
                                : // @ts-ignore
                                [planetNames[arrowSwitch]] === 'PixelVault'
                                ? null
                                : allPlanetWeights?.['updao']?.[
                                      planetNames[arrowSwitch]
                                  ]?.['circulating']
                        }
                    </span>
                    <span>
                        <span className="label">UPDAO Votes:</span>{' '}
                        {updaoVotes[arrowSwitch]}
                    </span>
                    {
                        // @ts-ignore
                        planetNames[arrowSwitch] !== 'PixelVault' ? (
                            
                                <span>
                                <span className="label">Activation Quorum:</span>{' '}
                                {
                                    // @ts-ignore
                                    [planetNames[arrowSwitch]] === 'PixelVault'
                                        ? 1
                                        : // @ts-ignore
                                          allPlanetWeights?.['updao']?.[
                                              planetNames[arrowSwitch]
                                          ]?.['activate']
                                }
                            </span>
                            
                        ) : null
                    }
                    
                    { 
                    // @ts-ignore
                    planetNames[arrowSwitch] !== 'PixelVault' ? (
                        <span>
                        <span className="label">Voting Quorum:</span>{' '}
                        {
                            // @ts-ignore
                            [planetNames[arrowSwitch]] === 'PixelVault'
                                ? 1
                                : // @ts-ignore
                                  allPlanetWeights?.['updao']?.[
                                      planetNames[arrowSwitch]
                                  ]?.['votingQuorum']
                        }
                    </span>
                    ) : null }
                </div>
                <img
                    src={arrowRight}
                    className="carousel-arrow"
                    onClick={() => setArrowSwitch(arrowSwitch + 1)}
                    alt='right arrow'
                />
            </div>
        </div>
    );
};
