import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import useData from '../../utils/dataContext';
import './SidePopUp.css';

const SidePopUp: React.FC = () => {
    const { chainMismatch } = useData();
    const { chainId } = useWeb3React<Web3Provider>();

    return (
        <>
            {!chainMismatch ? null : (
                <div className="popup-wrapper">
                    <div className="popup-item">
                        <span>Wrong Network.{ chainId === 1 ? 'Switch to Goerli' : 'Switch to Mainnet' }</span>
                    </div>
                </div>
            )}
        </>
    );
};

export default SidePopUp;
