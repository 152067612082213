export default function SuspiciousWallet (){
    return (
        <div className="treasury-portfolio-tab sus">
            <div className="treasury-info">
                <h1 className="treasury-title">Suspicious Wallet</h1>
                <p className="treasury-desc">
                    We have reasons to believe that your wallet has been reported for suspicious activity. We're sorry you can't use the UPDAO Governance platform. 
                </p>
            </div>
    </div>
    )
}