import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryGroup,
} from "victory";

import { formatPrice } from "./powTracker";

const intervals = [
  {
    label: "1H",
    value: 0.05,
  },
  {
    label: "1D",
    value: 1,
  },
  {
    label: "1W",
    value: 7,
  },
  {
    label: "1M",
    value: 30,
  },
  {
    label: "1Y",
    value: 360,
  },
  {
    label: "All",
    value: "max",
  },
];

const useGetChartData = (cryptoName, interval, options) => {
  return useQuery(
    ["chartData", interval],
    async () => {
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/${cryptoName}/market_chart?vs_currency=usd&days=${interval}`
      );
      return await response.json();
    },
    options
  );
};

const PowDataGraph = ({ cryptoName }) => {
  const [dataInterval, setDataInterval] = useState(intervals[0].value);

  const { isLoading, data } = useGetChartData(cryptoName, dataInterval, {
    refetchInterval: 60000,
    staleTime: 60000,
    select: (data) =>
      data?.prices?.map((item) => ({
        x: item[0],
        y: item[1],
      })),
  });

  return (
    <>
      <div className="chart">
        {isLoading ? (
          <div className="loading-container">
            <span>Loading...</span>
          </div>
        ) : (
          <VictoryGroup
            width={800}
            height={320}
            domainPadding={2}
            containerComponent={
              <VictoryVoronoiContainer
                labels={({ datum }) => formatPrice(datum.y)} // Format the price
                title={`${cryptoName} price data chart`} // For screen readers
                labelComponent={
                  <VictoryTooltip
                    style={{
                      fill: "#333",
                      fontSize: 22,
                    }}
                    flyoutStyle={{
                      fill: "#fff",
                      stroke: "#fff",
                      strokeWidth: 1,
                      margin: 10,
                    }}
                  />
                }
              />
            }
          >
            <VictoryLine
              style={{
                data: {
                  stroke: "#fff",
                  strokeWidth: 2,
                },
              }}
              data={data}
            />
          </VictoryGroup>
        )}
      </div>
      <div className="chart-actions">
        {intervals.map((interval) => (
          <button
            key={interval.value}
            className={dataInterval === interval.value ? "active" : "inactive"}
            onClick={() => setDataInterval(interval.value)}
          >
            {interval.label}
          </button>
        ))}
      </div>
    </>
  );
};

export default PowDataGraph;
