import { useState, useEffect } from 'react';
import { daysAgo } from '../../../../utils/functions';
// import PaginationBar from "../../paginationBar/paginationBar";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
// import useData from "../../../../utils/dataContext";
import './proposalsPageComments.css';
//COMPONENTS
import CommentDropDown from './commentFilter/commentDropDown';
import AddCommentModal from './AddCommentModal/AddCommentModal';
import ProposalsPageCommentBox from './proposalsPageCommentBox/proposalsPageCommentBox';
//ASSETS
import creatorImg from "../../../../images/profile.svg";
import { ReactComponent as AddComment } from "../../../../images/addcomment.svg";
import daoImg from "../../../../images/daomgr.svg";

type Comment = {
    author: string;
    comment: string;
    createdAt: string; // date string
    likedBy: string[];
    likes: number;
    mentions: string[];
    proposalId: string;
    updatedAt: string; // date string

    update: string;
    managerAddress: string;
};

type NewCommentResponse = {
    status: string;
    msg: string;
    created: Comment;
};

type ProposalsPageCommentBoxProps = {
    // idCB: number;
    proposal_id: string | null | undefined;
    updao_id: string | null | undefined;
    enacted: boolean;
    comments: any;
    commentPageNumber: number;
    setCommentPageNumber: any;
    isHolder: boolean;
    managerUpdates: any;
    setCommentRender: any;
};

const ProposalsPageComment: React.FC<ProposalsPageCommentBoxProps> = ({
    proposal_id,
    updao_id,
    enacted,
    comments,
    commentPageNumber,
    setCommentPageNumber,
    isHolder,
    managerUpdates,
    setCommentRender,
}) => {
    const filterCommentOptions = [
        { value: "Top Comments", label: "Top Comments" },
        { value: "Recent Comments", label: "Recent Comments" },
    ];
    const [commentOption, setCommentOption] = useState<any>(
        filterCommentOptions[0]
    );
    const { active, account } = useWeb3React<Web3Provider>();
    const [commentModal, setCommentModal] = useState<any>(false);
    const [commentSubmit, setCommentSubmit] = useState<any>(false);
    const [allComments, setAllComments] = useState<Comment[]>([]);

    if (enacted) {
        filterCommentOptions.push({
            value: "Updates",
            label: "Updates",
        });
    }

    useEffect(() => {
        if (managerUpdates.length !== 0 && commentOption.value === "Updates") {
            setAllComments(managerUpdates);
        } else if (
            comments.length !== 0 &&
            commentOption.value === "Top Comments"
        ) {
            let sorted_by_likes = comments.sort(function (a: any, b: any) {
                if (a.likes > b.likes) {
                    return -1;
                } else {
                    return 1;
                }
            });
            setAllComments([...sorted_by_likes]);
        } else if (
            comments.length !== 0 &&
            commentOption.value === "Recent Comments"
        ) {
            let sorted_by_date = comments.sort(function (a: any, b: any) {
                if (a.createdAt > b.createdAt) {
                    return -1;
                } else {
                    return 1;
                }
            });
            setAllComments([...sorted_by_date]);
        } else {
            setAllComments([]);
        }
    }, [commentOption, comments]);

    //checks if enacted and if theres any manager updates
    useEffect(() => {
        // console.log('manager update? ', managerUpdates)
        if(enacted && managerUpdates?.length > 0){
            console.log('enacted and manager updated')
            setCommentOption(filterCommentOptions[2])
        }
    }, [managerUpdates])

    const handleCommentSubmit = (commentResponse: NewCommentResponse) => {
        // console.log(commentResponse);
        if (commentResponse.status === "success") {
            allComments.push(commentResponse.created);
        }
        setCommentSubmit(!commentSubmit);
        setCommentRender(true);
        //just changes the commentSubmit state to trigger a rerender
    };

    const handleCommentModal = (isConnected: boolean) => {
        if (isConnected) {
            //open comment mod al is not connected
            !commentModal ? setCommentModal(true) : setCommentModal(false);
        } else {
            return;
        }
    };

    const DisplayCommentCreateButton = (isHolder: boolean) => {
        return active && isHolder ? (
            <button
                className={
                    active
                        ? "add-comment-button"
                        : "add-comment-button-inactive"
                }
                onClick={() => handleCommentModal(active)}
            >
                <span
                    className={
                        active
                            ? "add-comment-text"
                            : "add-comment-text-inactive"
                    }
                >
                    <AddComment
                        className="addComment-plus"
                        fill={active ? "white" : "gray"}
                    />
                    <p>Add Comment</p>
                </span>
            </button>
        ) : null;
    };

    return (
        <>
            <div className="comments-toggle">
                {comments.length === 0 && managerUpdates.length === 0 ? null : (
                    <CommentDropDown
                        mobile={false}
                        filterCommentOptions={filterCommentOptions}
                        setCommentOption={setCommentOption}
                        defCommentOption={commentOption}
                    />
                )}
                <div className="add-button">
                    {DisplayCommentCreateButton(isHolder)}
                    {/*AddCommentModal is hidden until add comment button is clicked*/}
                    {/* {!enacted ? <AddCommentButton /> : null} */}
                </div>
            </div>
            <AddCommentModal
                account_id={updao_id}
                walletAddress={active ? account : null}
                proposal_id={proposal_id}
                status={commentModal}
                handleCommentModal={handleCommentModal}
                handleCommentSubmit={handleCommentSubmit}
            />
            {allComments.length === 0 ? (
                <div className="no-comments-message">
                    {commentOption.value === "Updates"
                        ? "No Updates"
                        : "No Comments"}
                </div>
            ) : (
                allComments.map((comment: any, index: any) => (
                    <ProposalsPageCommentBox
                        key={index}
                        creatorAddress={
                            commentOption.value === "Updates"
                                ? comment.managerAddress
                                    ? comment.managerAddress
                                    : "0xD2a69Fa4C4830b1Cd497AAA4bcb392C2960daF83"
                                : comment.author
                        }
                        creatorImg={
                            commentOption.value === "Updates"
                                ? daoImg
                                : creatorImg
                        }
                        createdAt={
                            commentOption.value === "Updates"
                                ? ""
                                : daysAgo(comment.createdAt)
                        }
                        likesTotal={
                            commentOption.value === "Updates"
                                ? null
                                : comment.likes
                        }
                        comment={
                            commentOption.value === "Updates"
                                ? comment.update
                                : comment.comment
                        }
                        commentId={
                            commentOption.value === "Updates"
                                ? comment.proposalId
                                : comment._id
                        }
                        likedBy={
                            commentOption.value === "Updates"
                                ? null
                                : comment.likedBy
                        }
                    />
                ))
            )}
            {/* {allComments.length >= 1 || commentPageNumber != 1
            ?<PaginationBar pageNumber={commentPageNumber} setPageNumber={setCommentPageNumber}/>
            :null} */}
        </>
    );
};

export default ProposalsPageComment;
