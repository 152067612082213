import thumbsup from "../../../../../images/BlackThumbsup.svg";
import thumbsdown from "../../../../../images/BlackThumbsdown.svg";
import { ReactComponent as AbstainSVG } from "../../../../../images/abstain.svg";
import React, { forwardRef } from "react";

type voteButtonProps = {
    voteResponseName: string;
    voteId: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disable: boolean;
};

type AvoteButtonProps = {
    disable?: boolean;
    voteId?: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export type Ref = HTMLButtonElement;

export const VoteButton = forwardRef<Ref, voteButtonProps>((props, ref) => {
    const { voteResponseName, voteId, onClick, disable } = props;

    return (
        <button
            ref={ref}
            id={voteId}
            className="vote-button"
            onClick={onClick}
            disabled={disable}
        >
            <span>
                <img
                    style={{ paddingRight: "5px", cursor: "pointer" }}
                    src={voteResponseName === "Yes" ? thumbsup : thumbsdown}
                    alt="thumbs action"
                />
                {voteResponseName}
            </span>
        </button>
    );
});

export const AbstainVote = forwardRef<Ref, AvoteButtonProps>((props, ref) => {
    const { disable, onClick, voteId } = props;
    return (
        <button
            className="abstain-button"
            ref={ref}
            id={voteId}
            onClick={onClick}
            disabled={disable}
        >
            <span>
                <AbstainSVG style={{ marginRight: "8px" }} fill="White" />
                Abstain
            </span>
        </button>
    );
});
