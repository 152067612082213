import ButtonComponent from '../buttonComponent/buttonComponent';
import './planetaryCarousel.css';
import { PlanetCarousel } from './planetCarousel';

export const PlanetaryCarousel: React.FC = () => {
    return (
        <div className="carousel-container">
            <div className="carousel-left">
                <h1>Inhabitants Universe Governance</h1>
                <p>
                    The United Planets DAO (UPDAO) is the overarching governance
                    entity of the Inhabitants Universe, a gaming and social
                    networking platform comprised of nine planets and one moon.
                    Governance of the UPDAO is a collective effort of the UPDAO Council, 
                    comprised of the United Planets, the Founder's DAO, and Pixel Vault, Inc.
                </p>
                <ButtonComponent
                    target="_blank"
                    text={'Learn More'}
                    link="https://docs.updao.app/welcome-to-the-united-planets-dao-updao/updao-governance"
                />
            </div>

            <div className="carousel-right">
                <PlanetCarousel />
            </div>
        </div>
    );
};
