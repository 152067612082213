import * as React from 'react';
import { useEffect } from 'react';
import axiosInstance from '../axiosHelper/axios';

const DataContext = React.createContext<any | null>(null);

function useData() {
    const [walletSigned, setWalletSigned] = React.useState(false);
    const [walletAddress, setWalletAddress] = React.useState<any>();
    const [updaoAccount, setUpdaoAccount] = React.useState<any>();
    const [planetsNames, setPlanetsNames] = React.useState<string>('UPDAO');
    const [showSummary, setShowSummary] = React.useState(true);
    const [allPlanetBalance, setAllPlanetBalance] = React.useState<number[]>(
        []
    );
    const [founderDAOBalance, setFounderDAOBalance] = React.useState<number>();
    const [planetDropDown, setPlanetDropDown] = React.useState(false);
    const [powBalance, setPowBalance] = React.useState<number>();
    const [ethBalance, setEthBalance] = React.useState<number>();
    const [powCurrValue, setPowCurrValue] = React.useState<number>();
    const [allPlanetWeights, setAllPlanetWeights] = React.useState<Object[]>(
        []
    );
    const [ethCurrValue, setEthCurrValue] = React.useState<number>();
    const [chainMismatch, setChainMismatch] = React.useState<boolean>();
    const [walletSus, setWalletSus] = React.useState<boolean>();

    const getEthPrice = async () => {
        await axiosInstance
            .get(
                'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
            )
            .then((res) => {
                setEthCurrValue(res.data.ethereum.usd);
            });
    };

    const getPowPrice = async () => {
        await axiosInstance
            .get(
                'https://api.coingecko.com/api/v3/simple/price?ids=punks-comic-pow&vs_currencies=usd'
            )
            .then((res) => {
                setPowCurrValue(res.data['punks-comic-pow'].usd);
            });
    };

    const getAllPlanetsWeightInfo = async () => {
        await axiosInstance
            .get('planet/weightReality')
            .then((res) => {
                const info = res.data;
                setAllPlanetWeights(info);
            })
            .catch((err) => {
                console.log('ERROR: ', err);
            });
    };

    useEffect(() => {
        getEthPrice();
        getAllPlanetsWeightInfo();
        getPowPrice();
    }, []);

    return {
        walletSigned,
        setWalletSigned,
        walletAddress,
        setWalletAddress,
        updaoAccount,
        setUpdaoAccount,
        planetsNames,
        setPlanetsNames,
        showSummary,
        setShowSummary,
        allPlanetBalance,
        setAllPlanetBalance,
        founderDAOBalance,
        setFounderDAOBalance,
        planetDropDown,
        setPlanetDropDown,
        powBalance,
        setPowBalance,
        ethBalance,
        setEthBalance,
        powCurrValue,
        setPowCurrValue,
        allPlanetWeights,
        ethCurrValue,
        chainMismatch,
        setChainMismatch,
        walletSus,
        setWalletSus
    };
}

//wrap DataProvider in index
export const DataProvider: React.FC<any> = ({ children }) => {
    const data = useData();

    return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export default function DataConsumer() {
    return React.useContext(DataContext);
}
