import { PlanetQuorumCarousel } from './QuorumStatus/planetQuorumCarousel';
import useData from '../../../../../utils/dataContext';

//ASSETS
import greenCheck from '../../../../../images/green-check-status.svg';
import thumbsup from '../../../../../images/GreenThumbsup.svg';
import thumbsdown from '../../../../../images/RedThumbsdown.svg';
import { ReactComponent as AbstainSVG } from '../../../../../images/abstain.svg';
import { ReactComponent as Circle } from '../../../../../images/Circle.svg';
import './planetStatus.css';

interface ProposalIdProp {
    planetName?: string;
    status: string;
    votes?: any;
    forUpdaoProposal?: any;
    forUpdaoProposalStatus?: any;
    forFinalVotes?: any;
}

const QuorumStatus: React.FC<ProposalIdProp> = (props) => {
    const {
        status,
        votes,
        planetName,
        forUpdaoProposal,
        forUpdaoProposalStatus,
        forFinalVotes,
    } = props;
    const { allPlanetWeights } = useData();
    // const [majorityVote, setMajorityVote] = useState<string>();

    let s = window.location.pathname;
    s = s.substring(0, s.indexOf('proposals'));
    // let yesVotes = votes?.[0] ? votes?.[0] : 0;
    // let noVotes = votes?.[1] ? votes?.[1] : 0;
    // let abstainVotes = votes?.[2] ? votes?.[2] : 0;

    // useEffect(() => {
    //     if (yesVotes > noVotes && yesVotes > abstainVotes) {
    //         setMajorityVote('Yes');
    //     } else if (noVotes > yesVotes && noVotes > abstainVotes) {
    //         setMajorityVote('No');
    //     } else {
    //         setMajorityVote('Abstain');
    //     }
    // }, [votes]);

    let draftState =
        allPlanetWeights?.['updao']?.[`${planetName}`]?.['activate'];

    let activeState =
        allPlanetWeights?.['updao']?.[`${planetName}`]?.['votingQuorum'];

    const stateSetting = status === 'Draft' ? draftState : activeState;

    const renderColor = (status: string) => {
        switch (status) {
            case 'Closed':
                return '#DD3434';
            case 'Active':
                return '#42DD34';
            case 'Quorum':
                return '#0EA8FF';
            default:
                return '#F8B83C';
        }
    };

    const renderInfoText = (status: string) => {
        switch (status) {
            case 'Draft':
                return 'Planet must reach at least 1% active quorum in order for this proposal to become activated or the proposal is otherwise rejected. ';
            case 'Active':
            case 'Quorum':
            case 'Closed':
                return 'Planet must reach at least 10% voting quorum or the proposal is otherwise rejected. If voting quorum is reached then the majority vote towards Yes or No will apply. ';
            default:
                return '';
        }
    };

    return (
        <>
            {window.location.href.indexOf('proposals/UPDAO') > -1 ? (
                <PlanetQuorumCarousel
                    status={status}
                    planetsWeightInfo={allPlanetWeights}
                    finalVotes={forFinalVotes}
                    updaoProposalInfo={forUpdaoProposal}
                    updaoProposalStatus={forUpdaoProposalStatus}
                />
            ) : (
                <div className="planet-status">
                    <div className="planet-status-title">
                        <h3>
                            {status === 'Closed'
                                ? 'Results'
                                : 'Current results'}
                        </h3>
                        <div className="planet-status-light">
                            <Circle
                                style={{ paddingRight: '5%' }}
                                fill={renderColor(status)}
                            />
                            <div>{status}</div>
                        </div>
                    </div>

                    <div className="quorum-status-text">
                        {renderInfoText(status)}
                        <a href={`${s}planetary-ecosystem#voting-sec`}>
                            Learn more
                        </a>
                    </div>

                    <div className="planet-results">
                        <div className="votes">
                            <div>
                                <img
                                    className="spc-image"
                                    src={thumbsup}
                                    alt="accepted votes"
                                />
                                {votes?.[0]
                                    ? votes?.[0].toLocaleString('en-US')
                                    : 0}
                            </div>

                            <div>
                                <AbstainSVG
                                    className="spc-image"
                                    fill="#F8B83C"
                                />
                                {votes?.[2]
                                    ? votes?.[2].toLocaleString('en-US')
                                    : 0}
                            </div>
                            <div>
                                <img
                                    className="spc-image"
                                    src={thumbsdown}
                                    alt="denied votes"
                                />
                                {votes?.[1]
                                    ? votes?.[1].toLocaleString('en-US')
                                    : 0}
                            </div>
                        </div>
                        <span>
                            {status === 'Draft'
                                ? `${
                                      allPlanetWeights?.['updao']?.[
                                          `${planetName}`
                                      ]?.['activate']
                                  } to activate`
                                : 'Current Planet Votes'}
                        </span>
                    </div>
                    <div className="planet-quorum-met">
                        <span>
                            {votes?.['total'] >= stateSetting ? (
                                <>
                                    <img
                                        src={greenCheck}
                                        alt="green check mark"
                                    />{' '}
                                    {status === 'Draft'
                                        ? 'Activation Quorum Met'
                                        : 'Voting Quorum Met'}
                                </>
                            ) : (
                                <>
                                    <Circle
                                        className="grey-circle"
                                        fill={'rgba(112, 112, 124, 0.2)'}
                                    />
                                    {status === 'Draft'
                                        ? 'Activation Quorum'
                                        : 'Voting Quorum'}
                                </>
                            )}
                        </span>
                        <span>
                            {votes?.['total']
                                ? `${votes?.['total'].toLocaleString(
                                      'en-US'
                                  )}/${stateSetting}`
                                : `0/${stateSetting}`}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export default QuorumStatus;
