import { useEffect, useRef, useState } from 'react';
import axiosInstance from '../../../axiosHelper/axios';
import useData from '../../../utils/dataContext';
import { useParams, useNavigate } from 'react-router-dom';
import { daysAgo, truncate, truncateText } from '../../../utils/functions';
import ReactMarkdown from "react-markdown";

//COMPONENTS
import ProposalsPageComment from './proposalsPageComment/proposalsPageComments';
import VoteWidget from './SideWidgets/voteWidget/voteWidget';
import GenericInfoComponent from './SideWidgets/genericInfoComponent/genericInfoComponent';
import QuorumStatus from './SideWidgets/proposalStatus/planetStatus';

//ASSETS
import creatorImg from '../../../images/profile.svg';
import backArrow from '../../../images/back-arrow.svg';
import './proposalDetails.css';

interface Proposal {
    status: string;
    authorSignerAddress: string;
    createdAt: string;
    title: string;
    description: string;
    comments: any;
    planetDoc: string;
    votes?: {};
    planet: {
        name: string;
        tokenId: number;
        circulating: number;
    };
    voteTotals: number;
    startAt: string;
    endAt: string;
    updaoVoteTotals?: {};
    finalUpdaoVotes?: {};
    updaoStatus?: {};
    blockNum: number;
    tags: any[];
    postStatus: string;
    managerUpdates: {};
}

let dummyData = {
    status: '',
    authorSignerAddress: '',
    createdAt: '',
    title: '',
    description: '',
    planetDoc: '',
    comments: [],
    planet: {
        name: '',
        tokenId: 0,
        circulating: 0,
    },
    voteTotals: 0,
    startAt: '',
    endAt: '',
    updaoVoteTotals: {},
    finalUpdaoVotes: {},
    updaoStatus: {},
    blockNum: 0,
    tags: [],
    postStatus: 'Pending',
    managerUpdates: {},
};

type ProposalDetailProps = {
    isHolder: boolean;
};

const ProposalPage: React.FC<ProposalDetailProps> = (
    props: ProposalDetailProps
) => {
    const windowLength = window.innerWidth;
    let params = useParams();
    const navigate = useNavigate();
    let [proposal, setProposal] = useState<Proposal>(dummyData);
    let [commentPageNumber, setCommentPageNumber] = useState<number>(1);
    const [fdBlockBal, setFdBlockBal] = useState(0);
    const [totalBal, setTotalBal] = useState(-1);
    const [balArr, setBalArr] = useState<any>();
    const [fdBalArr, setFDBalArr] = useState<any>();
    const [retry, setRetry] = useState<boolean>();
    const [commentRender, setCommentRender] = useState<boolean>();
    const { updaoAccount } = useData();
    const { isHolder } = props;
    const planetname = params.planet_name;
    const [collapse, setCollapse] = useState<boolean>(false)
    const [ensProfileUrl, setEnsProfileUrl] = useState<string>('');

    const userNameRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (planetname) {
            getProposalDetails(planetname, commentPageNumber);
        }
    }, [commentPageNumber, commentRender]);

    useEffect(() => {
        if (updaoAccount && proposal.blockNum > 0 && totalBal === -1) {
            getProposalVoteBlock();
        }

        const interval = setInterval(() => {
            if (updaoAccount) {
                if (retry === true && proposal.blockNum > 0) {
                    getProposalVoteBlock();
                } else if (retry === false) {
                    return;
                }
            }
        }, 3000);

        if (retry === false) {
            clearInterval(interval);
        }

        if(updaoAccount && ensProfileUrl === ''){
            getEnsProfile(proposal.authorSignerAddress)
          }

        return () => clearInterval(interval);
        
    }, [proposal, updaoAccount, retry]);

    const getEnsProfile = async (walletAddress: string) => {
        await axiosInstance
            .get(`/accounts/ens/?address=${walletAddress}`)
            .then((res) => {
                if(res.data){
                    let ensUrl = res.data.ensInfo.ensInfo.avatar.linkage[7].content
                    setEnsProfileUrl(ensUrl)
                }
            })
            .catch((err) => {
                console.log(`No ens for proposal creator: ${err}`)
            })
    }

    const getProposalDetails = async (
        name_string: string,
        page_num: number
    ) => {
        const commentParameters = {
            params: { commentPageSize: 50, commentPage: page_num },
        };
        await axiosInstance
            .get(
                `/proposals/detail/${
                    name_string === 'UPDAO'
                        ? 'updao/?'
                        : `?planet=${name_string}`
                }&sortDir=desc&proposalId=${params.proposal_id}`,
                commentParameters
            )
            .then((res) => {
                const planetProposal = res.data;
                console.log('PLANET PRO: ', planetProposal)
                setProposal(planetProposal);
            })
            .catch((err) => {
                console.log('ERROR: ', err);
            });
        setCommentRender(false);
    };

    const getProposalVoteBlock = async () => {
        await axiosInstance
            .get(
                `/accounts/updao/balAtBlock/?address=${updaoAccount.address}&blockNum=${proposal.blockNum}`
            )
            .then((res) => {
                const proposalBlock = res.data;

                if (
                    proposalBlock.status === false ||
                    proposalBlock?.fdBal?.errors ||
                    proposalBlock?.planetBal?.errors
                ) {
                    setRetry(true);
                } else {
                    setRetry(false);
                    const proposalFD = proposalBlock?.fdBal?.data?.erc721Tokens;
                    const proposalPlanet =
                        proposalBlock?.planetBal?.data?.erc1155Balances;
                    let votingTokenSum = 0;
                    if (planetname === 'UPDAO') {
                        if (proposalFD) {
                            let fdBalArr: any = [];
                            for (let i = 0; i < proposalFD.length; i++) {
                                fdBalArr[i] = {
                                    identifier: Number(
                                        proposalFD[i]?.token?.identifier
                                    ),
                                };
                            }
                            setFDBalArr(fdBalArr);
                            setFdBlockBal(proposalFD.length);
                            votingTokenSum += proposalFD.length;
                        }

                        if (proposalPlanet) {
                            let updaoPlanetBal: any = [];
                            for (let i = 0; i < proposalPlanet.length; i++) {
                                let amountExact = Number(
                                    proposalPlanet[i]?.token?.balances[0]
                                        ?.valueExact
                                );
                                if (
                                    Number(
                                        proposalPlanet[i]?.token?.identifier
                                    ) != 3
                                ) {
                                    votingTokenSum += amountExact;

                                    updaoPlanetBal[i] = {
                                        amount: amountExact,
                                        identifier: Number(
                                            proposalPlanet[i]?.token?.identifier
                                        ),
                                    };
                                }
                            }
                            setBalArr(updaoPlanetBal);
                        }
                        setTotalBal(votingTokenSum);
                    } else {
                        let planetTokenSum = 0;
                        if (proposalPlanet) {
                            for (let i = 0; i < proposalPlanet.length; i++) {
                                if (
                                    Number(
                                        proposalPlanet[i].token.identifier
                                    ) === proposal.planet.tokenId
                                ) {
                                    planetTokenSum += Number(
                                        proposalPlanet[i].token.balances[0]
                                            .valueExact
                                    );
                                }
                            }
                        }
                        setTotalBal(planetTokenSum);
                    }
                }
            })
            .catch((err) => {
                console.log('ERROR: ', err);
            });
    };

    const showDescription = () => {
        collapse?setCollapse(false):setCollapse(true)
        userNameRef.current?.scrollIntoView()
    }

    // console.log(proposal);

    return windowLength > 600 ? (
        //DESKTOP VIEW
        <>
            <div className="proposalpage-buttons">
                <button
                    onClick={() => navigate(-1)}
                >
                    <span>
                        <img src={backArrow} alt="back" />
                        <span className="back-text">Back</span>
                    </span>
                </button>
            </div>

            <div className="comments-scroll">
                <div className="proposalpage-card ">
                    <div ref={userNameRef}/>
                    <div className="proposalpage-details">
                        <div className="details-container tiny">
                            <img
                                className="prop-img2"
                                src={ensProfileUrl !== ''?ensProfileUrl:creatorImg}
                                alt="profile"
                                width={18}
                                height={18}
                            />
                            <span className="author-address">
                                {proposal.authorSignerAddress}
                            </span>
                            <span className="other-span">
                                {daysAgo(proposal.createdAt)}
                            </span>
                        </div>

                        <div className="proposalsdetails-border">
                            <h1 id="proposal-title" className="proposalpage-title">
                                {proposal.title.indexOf(' ') >= 0
                                    ? proposal.title
                                    : truncateText(proposal.title, 45)}
                            </h1>
                            <div
                                className="moreinfo"
                                style={{
                                    lineHeight: '180%',
                                    transition: '0.2s ease',
                                    whiteSpace: 'break-spaces',
                                }}
                            >

                                <ReactMarkdown  components={{
                                    // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
                                    li: ({node, ...props}) => <i style={{color: 'blue'}} {...props} />
                                }}>
                                    {collapse
                                    ?truncateText(proposal.description, 100)
                                    :proposal.description}
                                </ReactMarkdown>
                                {proposal.description.length > 100?
                                <button className='collapse-button' onClick={() => showDescription()}>
                                    {collapse?"read more":"show less"}
                                </button>
                                : null
                                }
                            </div>
                            <div className="tags-container">
                                {windowLength > 1520 ? (
                                    proposal.tags?.map((tag) => (
                                        <span className="tags-text">
                                            {proposal.tags.length > 2
                                                ? truncateText(tag, 20)
                                                : tag}
                                        </span>
                                    ))
                                ) : (
                                    <>
                                        {proposal.tags?.[0] ? (
                                            <span className="tags-text">
                                                {proposal.tags?.[0]}
                                            </span>
                                        ) : null}
                                        {proposal.tags?.[1] ? (
                                            <span className="tags-text">
                                                {proposal.tags?.[1]}
                                            </span>
                                        ) : null}
                                        {proposal.tags?.[2] ? (
                                            <div className="tooltip-tag">
                                                <span className="tooltip-text-tag">
                                                    {proposal.tags.map(
                                                        (tag, i) => {
                                                            if (
                                                                i !== 0 &&
                                                                i !== 1
                                                            )
                                                                return (
                                                                    <>
                                                                        <>
                                                                            {
                                                                                tag
                                                                            }
                                                                        </>
                                                                        <br />
                                                                    </>
                                                                );
                                                        }
                                                    )}
                                                </span>
                                                <span className="tags-text">
                                                    +{proposal.tags.length - 2}
                                                </span>
                                            </div>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        </div>

                        <ProposalsPageComment
                            enacted={
                                proposal.postStatus !== 'Rejected' &&
                                proposal.status === 'Closed'
                                    ? true
                                    : false
                            }
                            updao_id={updaoAccount?._id}
                            proposal_id={params.proposal_id}
                            comments={proposal.comments}
                            commentPageNumber={commentPageNumber}
                            setCommentPageNumber={setCommentPageNumber}
                            isHolder={isHolder}
                            managerUpdates={proposal.managerUpdates}
                            setCommentRender={setCommentRender}
                        />
                    </div>
                    <div className="proposalpage-infosection">
                        <VoteWidget
                            blockNumber={proposal.blockNum}
                            currentVotes={totalBal}
                            postStatus={proposal.postStatus}
                            foundersBal={fdBlockBal}
                            updaoPlanetBalArr={balArr}
                            updaoFDBalArr={fdBalArr}
                            status={proposal.status}
                            propid={params.proposal_id}
                            planetId={proposal?.planet?.tokenId}
                            fetchNewDetails={getProposalDetails}
                            votes={proposal.votes}
                        />
                        <QuorumStatus
                            planetName={params.planet_name}
                            votes={proposal.votes}
                            status={proposal.status}
                            forFinalVotes={proposal.finalUpdaoVotes}
                            forUpdaoProposal={proposal.updaoVoteTotals}
                            forUpdaoProposalStatus={proposal.updaoStatus}
                        />
                        <GenericInfoComponent
                            ends={proposal.endAt}
                            starts={proposal.startAt}
                            status={proposal.status}
                            blockNumber={proposal.blockNum}
                        />
                    </div>
                </div>
            </div>
        </>
    ) : (
        //END OF DESKTOP VIEW
        //MOBILE VIEW
        <>
            <div className="proposalpage-buttons">
                <button
                    onClick={() => navigate(-1)}
                >
                    <span>
                        <img src={backArrow} alt="back" />
                        <span className="back-text">Back</span>
                    </span>
                </button>
                {/* Create Proposal button */}
            </div>

            <div className="comments-scroll">
                <div className="proposalpage-card ">
                    <div className="proposalpage-details">
                        <div className="details-container tiny">
                            <img
                                className="prop-img2"
                                src={ensProfileUrl !== ''?ensProfileUrl:creatorImg}
                                alt="profile"
                                width={18}
                                height={18}
                            />
                            <span className="author-address">
                                {truncate(proposal.authorSignerAddress)}
                            </span>
                            <span className="other-span">
                                {daysAgo(proposal.createdAt)}
                            </span>
                        </div>

                        <div className="proposalsdetails-border">
                            <h1 className="proposalpage-title ">
                                {proposal.title.indexOf(' ') >= 0
                                    ? proposal.title
                                    : truncateText(proposal.title, 45)}
                            </h1>
                            <div
                                className="moreinfo"
                                style={{
                                    lineHeight: '180%',
                                    transition: '0.2s ease',
                                    whiteSpace: 'break-spaces',
                                }}
                            >
                                <ReactMarkdown  components={{
                                    // Map `h1` (`# heading`) to use `h2`s.
                                    h1: 'h2',
                                    // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
                                    em: ({node, ...props}) => <i style={{color: 'red'}} {...props} />
                                }}>
                                    {collapse
                                    ?truncateText(proposal.description, 100)
                                    :proposal.description}
                                </ReactMarkdown>
                            </div>
                            <div className="tags-container">
                                {windowLength > 1520 ? (
                                    proposal.tags?.map((tag) => (
                                        <span className="tags-text">
                                            {proposal.tags.length > 2
                                                ? truncateText(tag, 20)
                                                : tag}
                                        </span>
                                    ))
                                ) : (
                                    <>
                                        {proposal.tags?.[0] ? (
                                            <span className="tags-text">
                                                {truncateText(
                                                    proposal.tags?.[0],
                                                    15
                                                )}
                                            </span>
                                        ) : null}
                                        {proposal.tags?.[1] ? (
                                            <span className="tags-text">
                                                {truncateText(
                                                    proposal.tags?.[1],
                                                    15
                                                )}
                                            </span>
                                        ) : null}
                                        {proposal.tags?.[2] ? (
                                            <span className="tags-text">
                                                +{proposal.tags.length - 2}
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="proposalpage-infosection">
                            {!updaoAccount ? (
                                <div className="card">
                                    <div className="cannot-vote-cont">
                                        <h2>Connect your wallet</h2>
                                        <small>
                                            To vote, connect a wallet holding a
                                            voting token at the time this
                                            proposal was created.
                                        </small>
                                    </div>
                                </div>
                            ) : (
                                <VoteWidget
                                    blockNumber={proposal.blockNum}
                                    currentVotes={totalBal}
                                    foundersBal={fdBlockBal}
                                    updaoPlanetBalArr={balArr}
                                    updaoFDBalArr={fdBalArr}
                                    status={proposal.status}
                                    postStatus={proposal.postStatus}
                                    propid={params.proposal_id}
                                    planetId={proposal?.planet?.tokenId}
                                    fetchNewDetails={getProposalDetails}
                                    votes={proposal.votes}
                                />
                            )}
                            <QuorumStatus
                                planetName={params.planet_name}
                                votes={proposal.votes}
                                status={proposal.status}
                                forFinalVotes={proposal.finalUpdaoVotes}
                                forUpdaoProposal={proposal.updaoVoteTotals}
                                forUpdaoProposalStatus={proposal.updaoStatus}
                            />
                            <GenericInfoComponent
                                ends={proposal.endAt}
                                starts={proposal.startAt}
                                status={proposal.status}
                                blockNumber={proposal.blockNum}
                            />
                        </div>
                    </div>
                    <ProposalsPageComment
                        enacted={
                            proposal.postStatus !== 'Rejected' &&
                            proposal.status === 'Closed'
                                ? true
                                : false
                        }
                        updao_id={updaoAccount?._id}
                        proposal_id={params.proposal_id}
                        comments={proposal.comments}
                        commentPageNumber={commentPageNumber}
                        setCommentPageNumber={setCommentPageNumber}
                        isHolder={isHolder}
                        managerUpdates={proposal.managerUpdates}
                        setCommentRender={setCommentRender}
                    />
                </div>
            </div>
        </>
    );
};

export default ProposalPage;
