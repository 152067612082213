import './voteWidget.css';

//ASSETS
import thumbsdown from '../../../../../images/RedThumbsdown.svg';
import thumbsup from '../../../../../images/GreenThumbsup.svg';
import abstainImg from '../../../../../images/abstain.svg';
import whitethumbsup from '../../../../../images/whiteThumbsUp.svg';

type stateProps = {
    majority?: string;
    postStatus: string;
};

export const VoteState: React.FC<stateProps> = (props) => {
    const { postStatus } = props;

    const votePending = {
        msg: 'is pending enactment',
        imgSrc: whitethumbsup,
    };

    const votePassed = {
        msg: 'has been enacted',
        imgSrc: thumbsup,
    };

    const voteFailed = {
        msg: 'has been rejected',
        imgSrc: thumbsdown,
    };

    const voteUnknown = {
        msg: 'in an indeterminate state',
        imgSrc: abstainImg,
    }

    let config;

    switch(postStatus) {
        case 'Pending Execution':
        case 'Pending Enactment':
            config = votePending;
            break;
        case 'Rejected':
            config = voteFailed;
            break;
        case 'Enacted':
            config = votePassed;
            break;
        default: 
            config = voteUnknown;
            break;
    }

    return (
        <>
            <div className="vote-state-container">
                <img
                    width="30px"
                    height="30px"
                    src={config.imgSrc}
                    alt="thumbs"
                />
                <h2>{`This proposal ${config.msg}`}</h2>
            </div>
        </>
    );
};
