import "./footer.css";
import pvlogo from "../../images/pvlogo.svg";

type FootProp = {
    planetEco?: boolean;
};

export const Footer: React.FC<FootProp> = ({ planetEco }) => {
    return (
        <div
            className={
                !planetEco ? "footer-container foot-pos" : "footer-container"
            }
        >
            <div className="footer-left">
                <img alt="company logo" src={pvlogo} width={24} height={24} />
                <span> &copy; Pixel Vault Inc. </span>
            </div>

            <div className="footer-middle">
                <a
                    style={{ filter: "blur(3px)" }}
                    rel="noreferrer"
                >
                    Coming Soon
                </a>
                <a
                    href="https://docs.updao.app/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Foundation Information
                </a>
            </div>

            <div className="footer-right">
                <a
                    href="mailto:info@updao.app"
                    target="_blank"
                    rel="noreferrer"
                >
                    Contact Us
                </a>
                <a
                    href="https://forms.gle/fQyy9dZnCPDuExWt7"
                    target="_blank"
                    rel="noreferrer"
                >
                    Report Bugs
                </a>
                <a
                    href={'/United_Planets_DAO_Foundation-Terms_of_Service_[FINAL].pdf'}
                    target="_blank"
                    rel="noreferrer"
                >
                    Terms
                </a>
                <a
                    href={'/United_Planets_DAO_Foundation-Privacy_Policy_[FINAL].pdf'}
                    target="_blank"
                    rel="noreferrer"
                >
                    Privacy
                </a>
            </div>
        </div>
    );
};
