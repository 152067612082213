import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axiosHelper/axios';
import useData from '../../../utils/dataContext';
import {
    truncate,
    truncateText,
    getTimeRelativeToNow,
} from '../../../utils/functions';

//ASSETS
import { ReactComponent as StatusColorSVG } from '../../../images/status.svg';
import chat from '../../../images/chat.svg';
import thumbsup from '../../../images/Thumbup.svg';
import thumbsdown from '../../../images/Thumbdown.svg';
import abstain from '../../../images/abstained.svg';
import mercury from '../../../images/Mercury.svg';
import venus from '../../../images/venuslight.svg';
import earth from '../../../images/Earth.svg';
import moon from '../../../images/Moon.svg';
import mars from '../../../images/marslight.svg';
import jupiter from '../../../images/jupiterlight.svg';
import saturn from '../../../images/saturnlight.svg';
import uranus from '../../../images/Uranus.svg';
import neptune from '../../../images/Neptune.svg';
import pluto from '../../../images/Pluto.svg';
import foundersDao from '../../../images/FoundersDaoBig.webp';
import profile from '../../../images/profile.svg';
import './ProposalCard.css';

type CommentBoxProps = {
    proposal_id: string;
    planet_name: string;
    creatorAddress: string;
    createdAt: string; // string, might change to Date later
    expiresAt: string; // string, might change to Date later
    title: string;
    description: string;
    tags: any[]; // Tag[] unclear what Tag type will be specifically, but an object with one key "name" is sufficient for now
    status: string;
    votes: {
        for: number;
        against: number;
        abstain: number;
    }; // ProposalVote : { option1Count: int, option2Count: int }
    middlePadding: boolean;
    updaoPlanetsState: any;
    reachedQuorumAmount: any[];
    updaoVoteTotals: any; //planet votes
    finalUpdaoVotes?: any; //final votes once proposal is closed
    propVoteStatus: string;
    quorumMet: boolean;
};

const updaohover = 'rgba(156, 156, 169, 0.74)';
const marshover = 'rgba(156, 156, 169, 0.74)';
const neptunehover = 'rgba(231, 246, 255, 0.74)';
const mercuryhover = 'rgba(231, 233, 255, 0.74)';
const uranushover = 'rgba(255, 231, 251, 0.4)';
const plutohover = 'rgba(255, 231, 247, 0.74)';
const jupiterhover = 'rgba(255, 211, 211, 0.74)';
const venushover = 'rgba(255, 231, 251, 0.74)';
const earthhover = 'rgba(234, 255, 231, 0.74)';
const saturnhover = 'rgba(255, 237, 231, 0.74)';
const moonhover = 'rgba(242, 231, 255, 0.74)';

const ProposalCard: React.FC<CommentBoxProps> = (props) => {
    const navigate = useNavigate();
    const windowWidth = window.innerWidth;
    const [cardhover, setCardHover] = useState(false);
    const [hoverColor, setHoverColor] = useState(updaohover);
    const { planetsNames, allPlanetWeights } = useData();
    const [commentTotal, setCommentTotal] = useState<number>(0);
    const [toActivate, setToActivate] = useState<number>(0);
    const [yesUpdaoVotes, setYesUpdaoVotes] = useState(0);
    const [noUpdaoVotes, setNoUpdaoVotes] = useState(0);
    const [abstainUpdaoVotes, setAbstainUpdaoVotes] = useState(0);
    // const [lockVotes, setLockVotes] = useState(false);
    const [ensProfileUrl, setEnsProfileUrl] = useState<string>(profile)
    const lockVotes = false;

    let yesUpdaoTemp = 0;
    let noUpdaoTemp = 0;
    let abstainUpdaoTemp = 0;
    let updaoToWin = 97;
    let setVotes = false;
    let totalUpdaoVotes = yesUpdaoTemp + noUpdaoTemp + abstainUpdaoTemp;
    let params = useParams();
    const {
        proposal_id,
        middlePadding,
        creatorAddress,
        createdAt,
        expiresAt,
        title,
        description,
        tags,
        status,
        votes,
        updaoPlanetsState,
        reachedQuorumAmount,
        updaoVoteTotals,
        finalUpdaoVotes,
        propVoteStatus,
        quorumMet
    } = props;

    let draftAmount = 0;
    let activeAmount = 0;

    const planetsInfo = [
        { planetName: 'Mercury', updaoVotes: 5, imgSrc: mercury },
        { planetName: 'Venus', updaoVotes: 9, imgSrc: venus },
        { planetName: 'Earth', updaoVotes: 8, imgSrc: earth },
        { planetName: 'Moon', updaoVotes: 12, imgSrc: moon },
        { planetName: 'Mars', updaoVotes: 3, imgSrc: mars },
        { planetName: 'Jupiter', updaoVotes: 20, imgSrc: jupiter },
        { planetName: 'Saturn', updaoVotes: 17, imgSrc: saturn },
        { planetName: 'Uranus', updaoVotes: 6, imgSrc: uranus },
        { planetName: 'Neptune', updaoVotes: 6, imgSrc: neptune },
        { planetName: 'Pluto', updaoVotes: 1, imgSrc: pluto },
        { planetName: 'FoundersDAO', updaoVotes: 19, imgSrc: foundersDao },
        // { planetName: 'pixelvault', updaoVotes: 87, imgSrc: pixelvault },
    ];

    useEffect(() => {
        setWins(status);
        getCommentTotals(proposal_id);
        const getEnsProfile = async (walletAddress: string) => {
            await axiosInstance
                .get(`/accounts/ens/?address=${walletAddress}`)
                .then((res) => {
                    if(res.data){
                        let ensUrl = res.data.ensInfo.ensInfo.avatar.linkage[7].content
                        setEnsProfileUrl(ensUrl)
                    }
                })
                .catch((err) => {
                    console.log(`Could not find ens profile: ${err}`)
                })
        }
        getEnsProfile(creatorAddress)
    }, [planetsNames, proposal_id]);

    useEffect(() => {
        if (!setVotes) {
            getVoteForTotals();
            //at the moment it seems like we assume pixel vault votes abstain if
            //the proposal is closed and did not meet voting quorum
            status === "Closed"
            ?setAbstainUpdaoVotes(abstainUpdaoTemp+87)
            :setAbstainUpdaoVotes(abstainUpdaoTemp)
            setVotes = true;
        }

        if (totalUpdaoVotes >= 97) {
            updaoToWin = 0;
        } else {
            updaoToWin = updaoToWin - totalUpdaoVotes;
        }
    }, [updaoVoteTotals]);

    const setWins = (proposalStatus: string) => {
        if (proposalStatus === 'Draft') {
            setToActivate(
                allPlanetWeights?.['updao']?.[planetsNames]?.['activate']
            );
        }
    };

    const statusColor = (status: string) => {
        if (status === 'Active') {
            return '#42DD34';
        } else if (status === 'Draft') {
            return '#F8B83C';
        } else if (status === 'Quorum') {
            return '#0EA8FF';
        } else {
            return '#DD3434';
        }
    };

    const cardHoverstyle = {
        cursor: 'pointer',
        border: `1px solid ${hoverColor} `,
    } as React.CSSProperties;

    useEffect(() => {
        switch (planetsNames) {
            case 'UPDAO':
                setHoverColor(updaohover);
                break;
            case 'Mercury':
                setHoverColor(mercuryhover);
                break;
            case 'Venus':
                setHoverColor(venushover);
                break;
            case 'Earth':
                setHoverColor(earthhover);
                break;
            case 'Moon':
                setHoverColor(moonhover);
                break;
            case 'Mars':
                setHoverColor(marshover);
                break;
            case 'Jupiter':
                setHoverColor(jupiterhover);
                break;
            case 'Saturn':
                setHoverColor(saturnhover);
                break;
            case 'Uranus':
                setHoverColor(uranushover);
                break;
            case 'Neptune':
                setHoverColor(neptunehover);
                break;
            case 'Pluto':
                setHoverColor(plutohover);
                break;
            default:
                setHoverColor(updaohover);
                break;
        }
    }, [planetsNames, props]);

    // Check vote for totals
    let planetsYes = (planet: string) => updaoVoteTotals?.[planet]?.['yes'];

    let planetsNo = (planet: string) => updaoVoteTotals?.[planet]?.['no'];

    let planetsAbstain = (planet: string) =>
    updaoVoteTotals?.[planet]?.['abstain'];

    const getVoteForTotals = () => {
        if (lockVotes) return;
        for (let i = 0; i < planetsInfo.length; i++) {
            let planet = planetsInfo[i].planetName;
            let planetsUpdaoVotes = planetsInfo[i].updaoVotes;
            let stat = updaoPlanetsState[planet];

            let yesVotes:number = planetsYes(planet)
            let noVotes:number = planetsNo(planet)
            let abstainVotes:number = planetsAbstain(planet)

            if (stat === 'Quorum') {
                if (
                    yesVotes > noVotes &&
                    yesVotes > abstainVotes
                ) {
                    yesUpdaoTemp += planetsUpdaoVotes;
                    setYesUpdaoVotes(yesUpdaoTemp);
                } else if (
                    noVotes > yesVotes &&
                    noVotes > abstainVotes
                ) {
                    noUpdaoTemp += planetsUpdaoVotes;
                    setNoUpdaoVotes(noUpdaoTemp);
                } else if (
                    abstainVotes > yesVotes &&
                    abstainVotes > noVotes
                ) {
                    abstainUpdaoTemp += planetsUpdaoVotes;
                    setAbstainUpdaoVotes(abstainUpdaoTemp);
                } else {
                    if (
                        yesVotes > noVotes &&
                        yesVotes === abstainVotes
                    ) {
                        yesUpdaoTemp += planetsUpdaoVotes;
                        setYesUpdaoVotes(yesUpdaoTemp);
                    }
                    // NO > YES && NO === ABSTAIN
                    else if (
                        noVotes > yesVotes &&
                        noVotes === abstainVotes
                    ) {
                        noUpdaoTemp += planetsUpdaoVotes;
                        setNoUpdaoVotes(noUpdaoTemp);
                    }
                    // YES === NO === ABSTAIN
                    else if (
                        yesVotes === noVotes &&
                        yesVotes === abstainVotes
                    ) {
                        noUpdaoTemp += planetsUpdaoVotes;
                        setNoUpdaoVotes(noUpdaoTemp);
                    } else {
                        noUpdaoTemp += planetsUpdaoVotes;
                        setNoUpdaoVotes(noUpdaoTemp);
                    }
                }
            } 
            if(finalUpdaoVotes){
                setYesUpdaoVotes(finalUpdaoVotes['yes'])
                setNoUpdaoVotes(finalUpdaoVotes['no'])
                setAbstainUpdaoVotes(finalUpdaoVotes['abstain'])
            }
        }
    };

    const getCommentTotals = (proposalId: string) => {
        let queryparams = { params: { proposalId: proposalId } };
        axiosInstance
            .get('/comment/totals/', queryparams)
            .then((res) => {
                const totalComments = res.data;
                setCommentTotal(totalComments);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    reachedQuorumAmount.forEach((reached) => {
        if (reached === 'Active' || reached === 'Quorum') {
            draftAmount += 1;
        } else {
            draftAmount += 0;
        }
    });

    reachedQuorumAmount.forEach((reached) => {
        if (reached === 'Quorum') {
            activeAmount += 1;
        } else {
            activeAmount += 0;
        }
    });

    const renderCircleColor = (planetStatus: string, propStatus: string) => {
        if (propStatus === 'Draft') {
            switch (planetStatus) {
                case 'Quorum':
                case 'Active':
                    return '1px solid #42DD34';
                default:
                    return '1px solid rgba(128, 126, 126, 0.28)';
            }
        } else if (propStatus === 'Active') {
            switch (planetStatus) {
                case 'Quorum':
                    return '1px solid #0EA8FF';
                default:
                    return '1px solid rgba(128, 126, 126, 0.28)';
            }
        }
    };

    const renderPropVoteStatus = (currStatus: string) => {
        if (status !== 'Closed') {
            if (params.planet_name === 'UPDAO') {
                return `Current UPDAO vote(s)`;
            } else {
                switch (status) {
                    case 'Quorum':
                    case 'Active':
                        return 'Current planet votes';
                    default:
                        return `${toActivate} to activate`;
                }
            }
        } else {
            switch (currStatus) {
                case 'Rejected':
                    return 'Proposal rejected';
                case 'Pending Execution':
                case 'Pending Enactment':
                    return 'Pending enactment';
                case 'Enacted':
                    return 'Proposal enacted';
                default:
                    return 'Proposal in an indeterminate state';
            }
        }
    };

    const voteConfigYes =
        params.planet_name === 'UPDAO' ? yesUpdaoVotes : votes.for;
    const voteConfigNo =
        params.planet_name === 'UPDAO' ? noUpdaoVotes : votes.against;
    const voteConfigAbstain =
        params.planet_name === 'UPDAO' ? abstainUpdaoVotes : votes.abstain;

    return (
        <>
            <div
                className="proposal-card"
                style={cardhover ? cardHoverstyle : undefined}
                onMouseEnter={() => setCardHover(true)}
                onMouseLeave={() => setCardHover(false)}
                onClick={() => navigate(`./${proposal_id}`)}
            >
                <div className="card-proposal-status">
                    <div className="card-proposal-status-leftSide">
                        <div className="details-container-left">
                            <div className="image-address-container">
                                <img
                                    // className="prop-img2"
                                    src={ensProfileUrl}
                                    alt="profile"
                                    width={18}
                                    height={18}
                                />
                                <div className="address">
                                    {truncate(creatorAddress)}
                                </div>
                            </div>

                            <div className="other-span">
                                {getTimeRelativeToNow(createdAt)}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="details-container-right">
                            <div className="dcr-date">
                                {getTimeRelativeToNow(expiresAt)}
                            </div>
                            <div className="proposal-status">
                                <div className="status-color">
                                    <StatusColorSVG
                                        fill={statusColor(status)}
                                    />
                                </div>
                                <div className="status-text">{status}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mid">
                    <div className="card-proposal-summary">
                        <h2 className="card-title">
                            {truncateText(title, 45)}
                        </h2>
                        <p className="card-description">
                            {truncateText(description, 130)}
                        </p>
                    </div>
                    {params.planet_name === 'UPDAO' &&
                    status !== 'Quorum' &&
                    status !== 'Closed' ? (
                        <div className="planets-list-cont">
                            <div className="planets-list-grid">
                                {planetsInfo.map((planet, index) => (
                                    <div
                                        className="list-circle"
                                        key={index}
                                        style={{
                                            border: renderCircleColor(
                                                updaoPlanetsState[
                                                    planet.planetName
                                                ],
                                                status
                                            ),
                                        }}
                                    >
                                        <img
                                            src={planet.imgSrc}
                                            className="planet-list-img"
                                        />
                                    </div>
                                ))}
                            </div>
                            <span>
                                {status === 'Draft'
                                    ? `${draftAmount}/11 Reached Activation Quorum`
                                    : `${activeAmount}/11 Reached Voting Quorum`}
                            </span>
                        </div>
                    ) : (
                        <div className="card-proposal-voting">
                            <div className="voting-numbers">
                                <div className="thumbs l">
                                    <img
                                        src={thumbsup}
                                        className="thumbs-image"
                                        alt="up vote"
                                    />
                                    {status === 'Closed' &&
                                    params.planet_name === 'UPDAO' && 
                                    quorumMet
                                        ? updaoVoteTotals?.['yes']
                                        : voteConfigYes}
                                </div>
                                <div className="thumbs l">
                                    <img
                                        src={abstain}
                                        className="thumbs-image"
                                        alt="up vote"
                                    />
                                    {status === 'Closed' &&
                                    params.planet_name === 'UPDAO' && 
                                    quorumMet
                                        ? updaoVoteTotals?.['abstain']
                                        : voteConfigAbstain}
                                </div>
                                <div className="thumbs 1">
                                    <img
                                        src={thumbsdown}
                                        className="thumbs-image"
                                        alt="down vote"
                                    />
                                    {status === 'Closed' &&
                                    params.planet_name === 'UPDAO' && 
                                    quorumMet
                                        ? updaoVoteTotals?.['no']
                                        : voteConfigNo}
                                </div>
                            </div>
                            <div className="votes-tracker">
                                {renderPropVoteStatus(propVoteStatus)}
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex-container">
                    <div
                        className={
                            middlePadding
                                ? 'flex-middleitem-left-second'
                                : 'flex-item-left-second'
                        }
                    >
                        <div className="card-tag-container">
                            <img
                                className="prop-img2"
                                src={chat}
                                alt="profile"
                                width={14}
                                height={14}
                            />
                            <span className="comment-total">
                                {commentTotal}
                            </span>
                            <div className="tags-cont">
                                {windowWidth > 1290 ? (
                                    tags.map((tag) => (
                                        <span className="card-individual-tag">
                                            {tag}
                                        </span>
                                    ))
                                ) : (
                                    <>
                                        {tags?.[0] ? (
                                            <span className="card-individual-tag">
                                                {truncateText(tags?.[0], 10)}
                                            </span>
                                        ) : null}
                                        {tags?.[1] ? (
                                            <span className="card-individual-tag">
                                                {truncateText(tags?.[1], 10)}
                                            </span>
                                        ) : null}
                                        {tags?.[2] ? (
                                            <div className="tooltip3">
                                                <span className="tooltip-text3">
                                                    {tags.map((tag, i) => {
                                                        if (i !== 0 && i !== 1)
                                                            return (
                                                                <>
                                                                    <>{tag}</>
                                                                    <br />
                                                                </>
                                                            );
                                                    })}
                                                </span>
                                                <span className="card-individual-tag">
                                                    +{tags.length - 2}
                                                </span>
                                            </div>
                                        ) : null}{' '}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProposalCard;
