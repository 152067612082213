//ASSETS
import vectorImage from "../../../../../images/vector.svg";

type infoProps<T> = {
    details: T;
};

export default function InfoRow<T>({ details }: infoProps<T>) {
    return (
        <>
            {Object.entries(details).map((obj, index) => (
                <li className="info-row" key={index}>
                    <span>{obj[0]}</span>
                    {obj[0] === ("ipfs" || "etherscan") ? (
                        <span>
                            {obj[1]} &nbsp;{" "}
                            <img src={vectorImage} alt="vector" />
                        </span>
                    ) : (
                        <span>{obj[1]}</span>
                    )}
                </li>
            ))}
        </>
    );
}
