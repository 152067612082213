import "./ProposalsTab.css";
 
const ProposalsTabContent = ({id, activeTab, children}) => {
    return (
      <div className="overviewprop-content">
     { children }
   </div>
    )
};
 
export default ProposalsTabContent;