type Error = {
    errorText: string;
    color: string;
};

const ErrorComponent: React.FC<Error> = (props) => {
    const { errorText, color } = props;
    return (
        <>
            <div
                style={{
                    borderLeft: `2px solid ${color}`,
                    paddingLeft: '10px',
                }}
            >
                <div
                    style={{
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '0.6em',
                        lineHeight: '174%',
                        color: '#ffffff',
                    }}
                >
                    {errorText}
                </div>
            </div>
        </>
    );
};

export default ErrorComponent;
