import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const DesktopStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      color: state.isFocused?"white":"white",
      background: "black",
      border: '1px solid rgba(156, 156, 169, 0.2)',
      borderRadius: '13px',
      padding: 15,
    }),
    options: (provided, state) => ({
      maxHeight: '150px'
    }),
    control: ( _, { selectProps: { width }}) => ({
      display: 'flex',
      width: width,
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused?'rgba(231, 231, 231, 0.09)':'none',
      marginBottom: '3px',
      borderRadius: '8px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = 'white';
  
      return { ...provided, opacity, transition, color };
    }
  }

export default function SelectTags({myOptions, setTags}) {
  return (
    <Select
      styles={DesktopStyles}
      closeMenuOnSelect={false}
      onChange={setTags}
      components={animatedComponents}
    //   defaultValue={[myOptions[0]]}
      isMulti
      options={myOptions}
    />
  );
}