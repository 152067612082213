import { useQuery } from "react-query";
import PowDataGraph from "./powDataGraph";
import powImage from "../../../images/pow.svg";

const cryptoName = "punks-comic-pow";

const useGetCardData = (cryptoName, options) => {
  return useQuery(
    `${cryptoName}-card`,
    async () => {
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/${cryptoName}`
      );
      return await response.json();
    },
    options
  );
};

export const formatPrice = (price) => {
  if(!price) return;

  const formatConfig = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  return formatConfig.format(price);
};

const formatPlusMinus = (priceChange) => {
  if(!priceChange) return;
  const isPositive = Math.sign(priceChange) >= 0;

  return (
    <span className={`${isPositive ? "positive" : "negative"}`}>
      {`${isPositive ? "⬆" : "🔻"}${priceChange.toFixed(2)}%`}
    </span>
  );
};

const PowTracker = () => {
  const { data, isLoading } = useGetCardData(cryptoName, {
    refetchInterval: 60000,
    staleTime: 60000,
  });

  if (isLoading) return null;

  const { market_data: marketData } = data;

  return (
    <div className="price-card">
      <div className="card-inner">
        <div className="top-data">
          <div className="pow-image">
            <img src={powImage} alt="pow" />
            <span className="proposals-text">POW (POW)</span>  
          </div>
          {marketData ? (
            <h4 className="crypto-price">
              {formatPrice(marketData?.current_price?.usd)}
              {formatPlusMinus(marketData?.price_change_percentage_24h)}
            </h4>
          ) : null}
        </div>
        <PowDataGraph cryptoName={cryptoName} />
      </div>
    </div>
  );
};

export default PowTracker;
