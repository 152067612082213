import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../axiosHelper/axios';
import { removeSpaces } from '../../../../utils/functions';
import { useWeb3React } from '@web3-react/core';
import SelectPlanetDropDown from './SelectPlanetDropDown.js';
import SelectTags from './SelectTags';
import { generateSignedPayload } from '../../../../utils/functions';
import useData from '../../../../utils/dataContext';
import SimpleTextArea from './simple';

//ASSETS
import profileImage from '../../../../images/profile.svg';
import { ReactComponent as CloseButtom } from '../../../../images/CloseButton.svg';
import './ProposalModal.css';

interface ModalProps {
    account_id: string | null | undefined;
    status: boolean;
    modalTagOptions: any;
    handleModal: any;
}

const ProposalModal: React.FC<ModalProps> = ({
    account_id,
    status,
    modalTagOptions,
    handleModal,
}) => {
    const { allPlanetBalance } = useData();

    const allPlanetOptions: any = [
        { value: 'UPDAO', label: 'UPDAO' },
        { value: 'Mercury', label: 'Mercury' },
        { value: 'Venus', label: 'Venus' },
        { value: 'Earth', label: 'Earth' },
        { value: 'Moon', label: 'Moon' },
        { value: 'Mars', label: 'Mars' },
        { value: 'Jupiter', label: 'Jupiter' },
        { value: 'Saturn', label: 'Saturn' },
        { value: 'Uranus', label: 'Uranus' },
        { value: 'Neptune', label: 'Neptune' },
        { value: 'Pluto', label: 'Pluto' },
    ];

    //filters planet drop down based on if you have tokens for that planet
    let planetOptions: any = allPlanetOptions.filter(
        (planet: any, index: number) => {
            if (planet.value === 'UPDAO') {
                return planet;
            } else if (allPlanetBalance[index - 1] > 0) {
                return planet;
            }
        }
    );

    //To get user information
    const { active, account, library } = useWeb3React();
    let params: any = useParams();
    const navigate = useNavigate();
    //State for Form
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState(`Proposal Title - What is the name of your proposal?  

Status - Idea, concept, in review, approved by the community, ready for quorum, etc.  

Implementer - Who will be making this proposal come to life (ie. UPDAO Council, Inhabitants Universe, Pixel Vault, UPDAO Community, Development Team, Legal Team, etc.)?  

Category - Financial, Structural, Partnerships, Gaming, Informational, Ideation, Branding, Fund Allocation or Other; please also notate whether a proposal is seeking to remove funds from the UPDAO to a separate wallet or whether UPDAO administers the funds (NOTE: KYC may be required); what department of the UPDAO will this fall under?  

Proposal Committee - Who is submitting this proposal?  

Sponsor(s) - Who is vouching for this proposal? (NOTE: If an idea is fully fleshed out, an individual is more than welcome to submit their own proposals. Submitting as a team is heavily encouraged so you know the idea is endorsed by other members of the DAO); would you like to implement a community review process? What does this process look like and who will be involved?  

Date of Submission - When are you submitting this proposal?  

Abstract (Pitch) - A short description of the proposal not to exceed 3 sentences  

Motivation (Goal) - A more in-depth description of the proposed project with a final goal of what you hope to achieve; what would be a successful outcome?  

Rationale (Why) - How does it align with the UPDAO’s mission and values? What will this proposal add to the UPDAO and its citizens?  

Steps to Implement - How would you go about making this come to life? Details as to how you might envision completing this project, ideally step by step.  

Timeline - When would we be starting this project? What are the milestones and dates of these completed milestones? When will this project be completed? What is the breakdown of cost over time?`);
  
const [selectedPlanet, setSelecteedPlanet] = useState<any>(null);
    const [tags, setTags] = useState<any>([]);
    //Error handling for form
    const [submissionErr, setSubmissionErr] = useState<boolean>();
    const [fillProposalErr, setFillProposalErr] = useState<boolean>();

    let provider = active ? library?.provider : undefined;

    useEffect(() => {
        let getCurrPlanet = null;
        for (let i = 0; i < allPlanetOptions.length; i++) {
            if (allPlanetOptions[i].value === params.planet_name) {
                getCurrPlanet = allPlanetOptions[i];
            }
        }
        setSelecteedPlanet(getCurrPlanet);
    }, [params.planet_name]);

    const handleCloseAndClearState = () => {
        handleModal(status);
        setFillProposalErr(false);
        setSubmissionErr(false);
    };

    const submitProposal = async (
        title: string,
        description: string,
        tags: any,
        selectedPlanet: string
    ) => {
        let adjustedTags = tags.map((e: any) => {
            return e.value;
        });

        //check that title and desc. are not just spaces
        const titleNoSpaces = removeSpaces(title);
        const descriptionNoSpaces = removeSpaces(description);

        const msgBody = {
            author: account,
            title,
            description,
            tags: adjustedTags,
            planet: selectedPlanet,
            signerAddress: account,
        };

        if (titleNoSpaces !== '' && descriptionNoSpaces !== '') {
            const signature = await generateSignedPayload(provider, msgBody);
            axiosInstance
                .post('proposals/create', {
                    msgBody,
                    signature,
                })
                .then((res) => {
                    setTitle('');
                    setDescription('');
                    handleModal(status);
                    navigate(`../${res.data.planet}/${res.data._id}`);
                    setFillProposalErr(false);
                })
                .catch((err) => {
                    console.error('Submission Error: ', err);
                    setSubmissionErr(true);
                });
        } else {
            setFillProposalErr(true);
            setSubmissionErr(false);
        }
    };

    if (status) {
        return (
            <div className="create-proposal-background">
                <form className="create-proposal-form">
                    <div className="cpf-top">
                        <div>
                            <img
                                className="cpf-modal-profile"
                                src={profileImage}
                                alt="profile"
                            />
                            <div className="cpf-account-id">
                                {account_id}
                            </div>
                        </div>
                        <div
                            className="cpf-close-modal cpf-x-button"
                            onClick={() => handleCloseAndClearState()}
                        >
                            <CloseButtom className="cpf-tag-close cpf-x-button" />
                        </div>
                    </div>
                    <input
                        id="cpf-title-input"
                        className="cpf-inputs"
                        type="text"
                        placeholder="Add a title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength={300}
                    />
                    <SimpleTextArea
                        value={description}
                        setValue={setDescription}
                    />
                    <div className="cpf-bottom-mid">
                        <div className="cpf-bottom-mid-text">Tags: </div>
                        <SelectTags
                            myOptions={modalTagOptions}
                            setTags={setTags}
                        />
                    </div>
                    <div className="cpf-bottom">
                        <div className="cpf-bottom-left">
                            <div className="cpf-bottom-left-text">
                                Proposal for:{' '}
                            </div>
                            <SelectPlanetDropDown
                                mobile={false}
                                planetOptions={planetOptions}
                                setSelecteedPlanet={setSelecteedPlanet}
                                currPlanet={params.planet_name}
                            />
                        </div>
                        <div
                            className="sumbit-proposal-button button-pointer"
                            onClick={() =>
                                submitProposal(
                                    title,
                                    description,
                                    tags,
                                    selectedPlanet.value
                                )
                            }
                        >
                            Submit Proposal
                        </div>
                    </div>
                </form>
                {submissionErr ? (
                    <div className="proposal-error-message">
                        There was an error submitting your proposal.
                    </div>
                ) : null}
                {fillProposalErr ? (
                    <div className="proposal-error-message">
                        Please fill in all sections above.
                    </div>
                ) : null}
            </div>
        );
    } else {
        return <div style={{ display: 'none' }}></div>;
    }
};

export default ProposalModal;
