import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import './connectWidget.css';
// import { ConnectButton } from '../../connectButtonComponent/connectButtonComponent';
// import useData from '../../../utils/dataContext';
import { ConnectButtonSecond } from '../../connectButtonComponent/connectButtonSecond';

const ConnectWidget: React.FC = () => {
    // const {walletSigned, walletAddress, account} = useData();
    const { account } = useWeb3React<Web3Provider>();
    return (
        <div className="connect-card">
            <div className="widget-item">
                <h2 className="title">Connect Your Wallet</h2>
                <div className="description">
                    Connect your wallet to view your <br /> proposals and
                    holdings.
                </div>
                <div className="actions">
                    {!account ? (
                        <ConnectButtonSecond widgetPadding={true} />
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default ConnectWidget;
