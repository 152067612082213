//ASSETS
import mercury from "../../images/Mercury.svg";
import venus from "../../images/venuslight.svg";
import earth from "../../images/Earth.svg";
import moon from "../../images/Moon.svg";
import mars from "../../images/marslight.svg";
import jupiter from "../../images/jupiterlight.svg";
import saturn from "../../images/saturnlight.svg";
import uranus from "../../images/Uranus.svg";
import neptune from "../../images/Neptune.svg";
import pluto from "../../images/Pluto.svg";
import foundersDaoBig from "../../images/FoundersDaoBig.webp";
import useData from "../../utils/dataContext";
import "./proposalsHoldingsBox.css";

const ProposalsHoldingsBox: React.FC = () => {
  const { allPlanetBalance, founderDAOBalance } = useData();
  return (
    <>
      <div className="container-holdings">
        <div className="flexed item-left">
          <img
            src={mercury}
            alt="Mercury"
            style={JSON.stringify(allPlanetBalance[0]) === '0' ? {opacity: 0.3} : {opacity: 1} }
          />
          <p>Mercury</p>
          <h6>{allPlanetBalance[0]} owned</h6>
        </div>
        <div className="flexed item-middle">
          <img src={venus} alt="Venus" style={JSON.stringify(allPlanetBalance[1]) === '0' ? {opacity: 0.3} : {opacity: 1} }
          />
          <p>Venus</p>
          <h6>{allPlanetBalance[1]} owned</h6>
        </div>
        <div className="flexed item-right">
          <img src={earth} alt="Earth" style={JSON.stringify(allPlanetBalance[2]) === '0' ? {opacity: 0.3} : {opacity: 1} }
          />
          <p>Earth</p>
          <h6>{allPlanetBalance[2]} owned</h6>
        </div>
      </div>

      <div className="container-holdings">
        <div className="flexed item-left">
          <img src={moon} alt="Moon"style={JSON.stringify(allPlanetBalance[3]) === '0' ? {opacity: 0.3} : {opacity: 1} }
          />
          <p>Moon</p>
          <h6>{allPlanetBalance[3]} owned</h6>
        </div>
        <div className="flexed item-middle">
          <img src={mars} alt="Mars" style={JSON.stringify(allPlanetBalance[4]) === '0' ? {opacity: 0.3} : {opacity: 1} }
          />
          <p>Mars</p>
          <h6>{allPlanetBalance[4]} owned</h6>
        </div>
        <div className="flexed item-right">
          <img src={jupiter} alt="Jupiter" style={JSON.stringify(allPlanetBalance[5]) === '0' ? {opacity: 0.3} : {opacity: 1} }
          />
          <p>Jupiter</p>
          <h6>{allPlanetBalance[5]} owned</h6>
        </div>
      </div>
      <div className="container-holdings">
        <div className="flexed item-left">
          <img src={saturn} alt="Saturn" style={JSON.stringify(allPlanetBalance[6]) === '0' ? {opacity: 0.3} : {opacity: 1} }
          />
          <p>Saturn</p>
          <h6>{allPlanetBalance[6]} owned</h6>
        </div>
        <div className="flexed item-middle">
          <img src={uranus} alt="Uranus" style={JSON.stringify(allPlanetBalance[7]) === '0' ? {opacity: 0.3} : {opacity: 1} }
          />
          <p>Uranus</p>
          <h6>{allPlanetBalance[7]} owned</h6>
        </div>
        <div className="flexed item-right">
          <img src={neptune} alt="Neptune" style={JSON.stringify(allPlanetBalance[8]) === '0' ? {opacity: 0.3} : {opacity: 1} }
          />
          <p>Neptune</p>
          <h6>{allPlanetBalance[8]} owned</h6>
        </div>
      </div>
      <div className="container-holdings">
        <div className="flexed item-left">
          <img src={pluto} alt="Pluto" style={JSON.stringify(allPlanetBalance[9]) === '0' ? {opacity: 0.3} : {opacity: 1} }
          />
          <p>Pluto</p>
          <h6>{allPlanetBalance[9]} owned</h6>
        </div>
        <div className="flexed item-middle">
          <img src={foundersDaoBig} alt="Founder's DAO" style={JSON.stringify(founderDAOBalance) === '0' ? {opacity: 0.3} : {opacity: 1} }
          />
          <h5>Founder's DAO</h5>
          <h6>{founderDAOBalance} owned</h6>
        </div>
        <div className="filler"></div>
      </div>
    </>
  );
};

export default ProposalsHoldingsBox;
