import React, { useState } from "react";
import "./Treasury.css";
import useData from "../../../utils/dataContext";
// import Select from "react-select";
import TreasuryPortfolio from "./TreasuryPortfolio";
// import TreasuryHistory from "./TreasuryHistory";
import TreasuryNFT from "./TreasuryNFT";

// import EthNetwork from "../../../images/ethnetwork.svg";

type ImageProps = {
    treasuryImage: string;
};

// const options = [
//     { value: "Portfolio", label: "Portfolio" },
//     { value: "NFTs", label: "NFTs" },
// ];

const Treasury: React.FC<ImageProps> = (props) => {
    const { showSummary, setShowSummary } = useData();
    const { treasuryImage } = props;
    const [iD, setID] = useState(1);

    const [tab, setTab] = useState<string>("Portfolio");
    const [part_id, setpart_id] = useState(null);

    function updateSummary(id: number) {
        setShowSummary(false);
        setID(id);
    }

    function handleSelectChange(part_id: any) {
        setTab(part_id.value);
    }

    const colourStyles = {
        control: (styles: any) => ({
            ...styles,
            minWidth: "130px",
            height: "42px",
            backgroundColor: "transparent",
            borderRadius: "54px",
            border: `1px solid rgba(156, 156, 169, 0.4)`,
            color: "white",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "18px",
        }),
        option: (
            styles: any,
            { data, isDisabled, isFocused, isSelected }: any
        ) => {
            const color = data.color;
            return {
                ...styles,
                backgroundColor: isDisabled ? "red" : "black",
                color: "#FFF",
                cursor: isDisabled ? "not-allowed" : "default",
            };
        },
        indicatorSeparator: (styles: any) => ({ display: "none" }),
    };

    const tabSwitch: any = (switchTab: string) => {
        switch (switchTab) {
            case "Portfolio":
                return <TreasuryPortfolio treasuryImage={treasuryImage} />;
            case "NFTs":
                return <TreasuryNFT />;
            //History was scraped for now... will leave here
            //to recycle or reuse for later
            // case "History":
            //   return <TreasuryHistory/>
            default:
                <div>Data transfer compromised by cosmic radiation...</div>;
        }
    };

    return (
        <div id="treasury-section">
            {/* <div id="treasury-navbar">
                <div id="tab-options">
                    <li
                        style={
                            tab === "Portfolio"
                                ? {
                                      color: "#fff",
                                      textDecoration: "underline",
                                      textUnderlineOffset: "70%",
                                  }
                                : { color: "#9c9ca9bd" }
                        }
                        className="tab-option"
                        onClick={() => setTab("Portfolio")}
                    >
                        Portfolio
                    </li>
                    <li
                        style={
                            tab === "NFTs"
                                ? {
                                      color: "#fff",
                                      textDecoration: "underline",
                                      textUnderlineOffset: "70%",
                                  }
                                : { color: "#9c9ca9bd" }
                        }
                        className="tab-option"
                        onClick={() => setTab("NFTs")}
                    >
                        NFT
                    </li>
                    <li className="tab-option" onClick={() => setTab("History")}>History</li>          
                </div>
                <div id="tab-options-mobile">
                    <select
            className="select-overlay"
            value={tab}
            onChange={(e) => setTab(e.target.value)}
          >
            <option value="Portfolio">Portfolio</option>
            <option value="NFTs">NFTs</option>
          </select>
                    <Select
                        defaultValue={options[0]}
                        options={options}
                        styles={colourStyles}
                        value={options.find((item) => item.value === part_id)}
                        onChange={handleSelectChange}
                    />
                </div>
                <a id="walletID" href="./">
                    <img src={EthNetwork} />
                    Ethereum: 0x231...712983712
                </a>
            </div> */}
            {tabSwitch(tab)}
        </div>
    );
};

export default Treasury;
