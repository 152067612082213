import NFTMetaHeroCard from "./NFTMetaHeroCard";

const nftmockdata = [
    {
        id: 3445,
        nft_link: "###"
    },
    {
        id: 3446,
        nft_link: "###",
    },
    {
        id: 3447,
        nft_link: "###",
    },
    {
        id: 3448,
        nft_link: "###",
    },
    {
        id: 3435,
        nft_link: "###",
    },
    {
        id: 3445,
        nft_link: "###",
    },
    {
        id: 3455,
        nft_link: "###",
    },
    {
        id: 3465,
        nft_link: "###",
    }
]

const TreasuryNFT = () => {
    return(
        <div className="treasury-nft-tab">
            {nftmockdata.map((data, index) => (
                <NFTMetaHeroCard key={index} metahero_props={data} />
            ))}
        </div>
    )
}

export default TreasuryNFT