import { PlanetaryCarousel } from "./PlanetaryCarousel/planetaryCarousel";
import PlanetsGrid from "./planetsGrid/planetsGrid";
import { SectionComponent } from "./sectionComponent/sectionComponent";
import VotingMechanics from "./votingMechanics/votingMechanics";

const PlanetaryEcosystem: React.FC = () => (
  <>
    <PlanetaryCarousel />
    <PlanetsGrid />
    <SectionComponent section="founders" />
    <VotingMechanics />
    {/* <SectionComponent section="defigame" /> */}
    <SectionComponent section="pow" />
  </>
);

export default PlanetaryEcosystem;
