import "./buttonComponent.css";

type ButtonComponentProps = {
    text: string;
    link: string;
    target?: string;
};

const ButtonComponent: React.FC<ButtonComponentProps> = (props) => {
    const { text, link, target } = props;
    return (
        <button className="planetary-btn">
            <a target={target} href={link}>
                {text}
            </a>
        </button>
    );
};

export default ButtonComponent;
