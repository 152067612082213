import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import useData from "../../../utils/dataContext";
import useFilter from "../../../utils/filterContext";
import ProposalDetails from "../proposalDetails/proposalDetails";
import Proposal from "../Proposals/Proposals";
import { getPlanetIdFromName } from "../../../utils/functions";

//ASSETS
import info from "../../../images/info.svg";
import updao from "../../../images/updao.svg";
import mercury from "../../../images/Mercury.svg";
import venuslight from "../../../images/venuslight.svg";
import earth from "../../../images/Earth.svg";
import moon from "../../../images/Moon.svg";
import marslight from "../../../images/marslight.svg";
import jupiterlight from "../../../images/jupiterlight.svg";
import saturnlight from "../../../images/saturnlight.svg";
import uranus from "../../../images/Uranus.svg";
import neptune from "../../../images/Neptune.svg";
import pluto from "../../../images/Pluto.svg";
import "./ProposalSection.css";


const planetNameList = [
    { planet: "UPDAO", img: updao },
    { planet: "Mercury", img: mercury },
    { planet: "Venus", img: venuslight },
    { planet: "Earth", img: earth },
    { planet: "Moon", img: moon },
    { planet: "Mars", img: marslight },
    { planet: "Jupiter", img: jupiterlight },
    { planet: "Saturn", img: saturnlight },
    { planet: "Uranus", img: uranus },
    { planet: "Neptune", img: neptune },
    { planet: "Pluto", img: pluto },
];

const ProposalSection: React.FC = () => {
    // let params = useParams();
    let navigate = useNavigate();
    const {
        allPlanetBalance,
        founderDAOBalance,
        planetDropDown,
        planetsNames,
        setPlanetsNames
    } = useData();

    const { 
        setPageNumber
    } = useFilter();

    const [isHolder, setIsHolder] = useState(false);

    const sum = allPlanetBalance.reduce(
        (tempSum: any, x: any) => tempSum + x,
        0
    );

    useEffect(() => {
        let planetId;
        if (allPlanetBalance.length && planetsNames) {
            if (planetsNames === "UPDAO") {
                const allPlanetTokenCount = allPlanetBalance.reduce(
                    (prev: number, curr: number) => prev + curr,
                    0
                );
                setIsHolder(!!(allPlanetTokenCount + founderDAOBalance));
            } else {
                planetId = getPlanetIdFromName(planetsNames);
                if (planetId === -1) {
                    return;
                } else if (planetId === 10) {
                    // moon is id 10 but index 3
                    setIsHolder(!!allPlanetBalance[3]);
                } else {
                    setIsHolder(!!allPlanetBalance[planetId]);
                }
            }
        }
    }, [allPlanetBalance, founderDAOBalance, planetsNames]);
    //state for proposal info of individual planets
    // const [planetName, setPlanetName] = useState<any>(planet_name);

    const closeMenu = () => {
        const el = document.querySelector(".sidebar");
        el?.classList.remove("dropdown");
    };

    const handlePlanetName = (name: string) => {
        setPlanetsNames(name)
        setPageNumber(1)
      }

    return (
        <>
            <div className="container-proposals">
                <div
                    className={planetDropDown ? "sidebar dropdown" : "sidebar"}
                >
                    {planetNameList.map((planetname, index) => (
                        <a
                            key={index}
                            className={
                                planetsNames === planetname.planet ? "active" : ""
                            }
                            onClick={() => {
                                closeMenu();
                                handlePlanetName(planetname.planet);
                                navigate(`/proposals/${planetname.planet}`);
                            }}
                        >
                            <span className="links">
                                <span className="col images">
                                    <img
                                        height={24}
                                        width={24}
                                        src={planetname.img}
                                        alt={planetname.img}
                                    />
                                </span>
                                <span className="col planet-text">
                                    {planetname.planet}
                                </span>
                                <span className="col planet-number">
                                    {planetname.planet === "UPDAO" ? (
                                        <div className="tooltip4">
                                            <div className="tooltip-text4">
                                                PVFD: {founderDAOBalance}{" "}
                                                Planets: {sum}
                                            </div>
                                            <img
                                                height={16}
                                                width={16}
                                                src={info}
                                                alt="info"
                                            />
                                        </div>
                                    ) : (
                                        <span>
                                            {allPlanetBalance[index - 1]}
                                        </span>
                                    )}
                                </span>
                            </span>
                        </a>
                    ))}
                </div>

                <div className="content">
                    <Routes>
                        <Route
                            path="/:planet_name"
                            element={<Proposal isHolder={isHolder} />}
                        />
                        <Route
                            path="/:planet_name/:proposal_id"
                            element={<ProposalDetails isHolder={isHolder} />}
                        />
                    </Routes>
                    {/* <Proposal planetName={planetName} planetProposals={planetProposals}/> */}
                </div>
            </div>
        </>
    );
};

export default ProposalSection;
