import React, { useEffect, useState } from 'react';
import PortfolioCryptoCard from './PortfolioCryptoCard';
import PortfolioBar from './PortfolioBar';
import ethereumToken from '../../../images/ethereumToken.svg';
import powToken from '../../../images/token.svg';
// import walletlink from "../../../images/walletlink.svg";
import EthNetwork from '../../../images/ethnetwork.svg';
import useData from '../../../utils/dataContext';
import ButtonComponent from '../../PlanetaryEcoSection/buttonComponent/buttonComponent';
// import apeToken from "../../../images/apetoken.svg";
// import usdToken from "../../../images/usdtoken.svg";

type ImageProps = {
    treasuryImage: string;
};

const TreasuryPortfolio: React.FC<ImageProps> = () => {
    // const { treasuryImage } = props;
    const { powBalance, ethBalance, powCurrValue, ethCurrValue } = useData();

    const ethRounded = Math.round(ethBalance);
    const ethWithComma = ethRounded?.toLocaleString('en-US');

    const ethToUsd = ethCurrValue ? ethBalance * ethCurrValue : null;

    const ethUsdWithComma = Math.round(ethToUsd ? ethToUsd : 0).toLocaleString(
        'en-US'
    );

    const powWithComma = powBalance?.toLocaleString('en-US');

    const powToUsd = powBalance * powCurrValue;
    const powUsdWithComma = Math.round(powToUsd).toLocaleString('en-US');

    const usdTotal = ethToUsd ? powToUsd + ethToUsd : null;

    const ethPercentage =
        usdTotal && ethToUsd ? (ethToUsd / usdTotal) * 100 : null;
    const powPercentage = usdTotal ? (powToUsd / usdTotal) * 100 : null;

    /* MOCK DATA FOR COIN CARDS*/
    const ethereum = {
        name: 'Ethereum',
        amount: ethWithComma,
        dollar_amount: ethUsdWithComma,
        wallet_link: '####',
        image: ethereumToken,
        currency: 'ETH',
    };

    const metahero = {
        name: 'Inhabitants Universe (POW)',
        amount: powWithComma,
        dollar_amount: powUsdWithComma,
        wallet_link: '####',
        image: powToken,
        currency: 'POW',
    };

    return (
        <div className="treasury-portfolio-tab">
            <div className="treasury-info">
                <h1 className="treasury-title">UPDAO Treasury</h1>
                <p className="treasury-desc">
                For those new to the Pixel Vault ecosystem, the UPDAO treasury was established during the planet mint last October,
                 and received 55% of the proceeds (7,888 ETH). The UPDAO treasury also controls over 600M $POW tokens, 
                 which will be used in our upcoming gaming ecosystem.
                </p>
                <ButtonComponent
                    text="Learn More"
                    target="_blank"
                    link="https://medium.com/@pixelvault/reimagining-daos-how-pixel-vault-simplified-the-complex-31607242f018"
                />
            </div>
            <div className="treasury-content">
                <div className="tpt-top">
                    <a
                        id="walletID"
                        href="https://etherscan.io/address/0x19C30Ad5EA4f7f9F36A8662b5FA2Cbc09E55FDED"
                        target="_blank"
                    >
                        <img src={EthNetwork} />
                        Ethereum: 0x19C...9E55FDED
                    </a>
                    <div className="tpt-top-info">
                        <div className="tpt-balance">
                            <div className="tpt-top-text">Crypto Net Worth</div>
                            <h1 className="tpt-top-h1">
                                ~$
                                {Math.round(
                                    usdTotal ? usdTotal : 0
                                ).toLocaleString('en-US')}{' '}
                            </h1>
                        </div>
                        <PortfolioBar
                            one={ethPercentage ? Math.round(ethPercentage) : 0}
                            oneCol="#8000FF"
                            // two={17}
                            // twoCol={"#FF8D23"}
                            three={
                                powPercentage ? Math.round(powPercentage) : 0
                            }
                            threeCol={'#14C918'}
                            // four={9}
                            // fourCol={"#0151EF"}
                        />
                        {/* <div className="tpt-top-disclaimer">
                            *Does not include the value of NFT holdings.
                        </div>
                        <a href="#" className="tpt-top-disclaimer">
                            Wallet Link <img src={walletlink} />
                        </a> */}
                    </div>
                    {/* <img className="tpt-planet-image" src={treasuryImage} /> */}
                </div>
                <div className="tpt-bottom">
                    <PortfolioCryptoCard props={ethereum} />
                    <PortfolioCryptoCard props={metahero} />
                    {/* <PortfolioCryptoCard props={usd} /> */}
                    {/* <PortfolioCryptoCard props={ape} /> */}
                </div>
            </div>
        </div>
    );
};

export default TreasuryPortfolio;
