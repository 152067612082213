import { useCallback, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import ReactDOMServer from "react-dom/server";
import SimpleMDE from "easymde";
import { SimpleMdeReact } from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

interface MyProps {
  value: any;
  setValue: any
}

const SimpleTextArea: React.FC<MyProps> = ({value , setValue}) => {

  const onChange = useCallback((valueString: string) => {
    setValue(valueString);
  }, []);

  const customRendererOptions = useMemo(() => {
    return {
      maxHeight: "35vh",
      hideIcons: ["image", "fullscreen", "side-by-side"],
      spellChecker: false,
      previewRender: (text) => {

        return ReactDOMServer.renderToString(
          <ReactMarkdown>
            {text}
          </ReactMarkdown>
        );
      },
    } as SimpleMDE.Options;
  }, []);

  return (
    <SimpleMdeReact  
      options={customRendererOptions}
      value={value}
      onChange={onChange}
    />
  )
}

export default SimpleTextArea;