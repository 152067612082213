import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import info from "../../../images/info.svg";
import updao from "../../../images/updao.svg";
// import mercury from "../../../images/Mercury.svg";
// import venuslight from "../../../images/venuslight.svg";
// import earth from "../../../images/Earth.svg";
// import moon from "../../../images/Moon.svg";
// import marslight from "../../../images/marslight.svg";
// import jupiterlight from "../../../images/jupiterlight.svg";
// import saturnlight from "../../../images/saturnlight.svg";
// import uranus from "../../../images/Uranus.svg";
// import neptune from "../../../images/Neptune.svg";
// import pluto from "../../../images/Pluto.svg";
import "./TreasurySection.css";
// import useData from "../../../utils/dataContext";
import Treasury from "../Treasury/Treasury";

// const numberPlanets: number = 12;

// const planetNames = [
//     { planet: "UPDAO", img: updao },
//     { planet: "Mercury", img: mercury },
//     { planet: "Venus", img: venuslight },
//     { planet: "Earth", img: earth },
//     { planet: "Moon", img: moon },
//     { planet: "Mars", img: marslight },
//     { planet: "Jupiter", img: jupiterlight },
//     { planet: "Saturn", img: saturnlight },
//     { planet: "Uranus", img: uranus },
//     { planet: "Neptune", img: neptune },
//     { planet: "Pluto", img: pluto },
// ];

const TreasurySection: React.FC = () => {
    // const { allPlanetBalance, founderDAOBalance } = useData();
    const [treasuryImage, setTreasuryImage] = useState(updao);
    // const { planetDropDown } = useData();
    // let navigate = useNavigate();

    return (
        <>
            <div className="treasury-container-proposals backimg">
                {/* TAKEN OUT FOR BETA */}
                {/* <div className={planetDropDown ? "sidebar dropdown" : "sidebar"}>
          {planetNames.map((planetname, index) => (
            <a
              key={index}
              className=""
              onClick={() => {
                // setPlanetsNames(planetname.planet);
                setTreasuryImage(planetname.img);
                navigate(`/treasury/${planetname.planet}`);
              }}
            >
              <span className="links">
                <span className="col images">
                  <img
                    height={24}
                    width={24}
                    src={planetname.img}
                    alt={planetname.img}
                  />
                </span>
                <span className="col planet-text">{planetname.planet}</span>
                <span className="col planet-number">
                  {planetname.planet === "UPDAO" ? (
                    <img height={16} width={16} src={info} alt="info" />
                  ) : (
                    <span>{allPlanetBalance[index - 1]}</span>
                  )}
                </span>
              </span>
            </a>
          ))}
        </div> */}
                <div className="content">
                    <Treasury treasuryImage={treasuryImage} />
                </div>
            </div>
        </>
    );
};

export default TreasurySection;
