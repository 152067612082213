// TwitterContainer.js
import { useEffect } from "react";
import './tweetContainer.css'

const TweetContainer = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document.getElementsByClassName("twitter-embed")[0].appendChild(script);
  }, []);

  return (
      <div className="tweet-scroll">
          <div className="twitter-embed">
                <a
                className="twitter-timeline"
                data-theme="dark"
                data-tweet-limit="10"
                data-chrome="noheader nofooter noborders"
                href="https://twitter.com/UPDAO_"
                >
                Tweets by PixelVault
                </a>
            </div>
      </div>
  );
};

export default TweetContainer;