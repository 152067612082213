import { ReactComponent as Circle } from "../../../images/Circle.svg";

type barProps = {
    one: number;
    oneCol: string;
    // two: number;
    // twoCol: string;
    three: number;
    threeCol: string;
    // four: number;
    // fourCol: string;
};

const PortfolioBar: React.FC<barProps> = ({
    one,
    oneCol,
    // two,
    // twoCol,
    three,
    threeCol,
    // four,
    // fourCol,
}) => {
    //BACKGROUND COLORS
    //ETH : #8000FF
    //USDC : #FF8D23
    //POW : #14C918
    //APE : #0151EF

    const ContainerStyles = {
        height: 8,
        width: "100%",
        borderRadius: 50,
    };

    const OneStyles = {
        borderRadius: "inherit",
        marginRight: "1%",
        width: `${one - 1}%`,
        backgroundColor: oneCol,
    };
    // const TwoStyles = {
    //     borderRadius: "inherit",
    //     marginRight: "1%",
    //     width: `${two - 1}%`,
    //     backgroundColor: twoCol,
    // };
    const ThreeStyles = {
        borderRadius: "inherit",
        marginRight: "1%",
        width: `${three - 1}%`,
        backgroundColor: threeCol,
    };

    // const FourStyles = {
    //     borderRadius: "inherit",
    //     marginRight: "1%",
    //     width: `${four - 1}%`,
    //     backgroundColor: fourCol,
    // };

    const CircleStyle = {
        marginTop: "4%",
        marginRight: "8%",
    };

    return (
        <div className="tpt-percentage">
            <div className="tpt-percentage-bar" style={ContainerStyles}>
                <div style={OneStyles}></div>
                {/* <div style={TwoStyles}></div> */}
                <div style={ThreeStyles}></div>
                {/* <div style={FourStyles}></div> */}
            </div>
            <div className="tpt-crypto-percent">
                <span className="tpt-text-percent">
                    <Circle style={CircleStyle} fill={oneCol} />
                    {one}% ETH
                </span>
                {/* <span className="tpt-text-percent">
                    <Circle style={CircleStyle} fill={twoCol} />
                    {two}% USDC
                </span> */}
                <span className="tpt-text-percent">
                    <Circle style={CircleStyle} fill={threeCol} />
                    {three}% POW
                </span>
                {/* <span className="tpt-text-percent">
                    <Circle style={CircleStyle} fill={fourCol} />
                    {four}% APE
                </span> */}
            </div>
        </div>
    );
};

export default PortfolioBar;
