import React, { useEffect, useState } from 'react';
import './proposalsWidget.css';
import axiosInstance from '../../../axiosHelper/axios';
import profile from '../../../images/profile.svg';
import { useNavigate } from 'react-router-dom';
import ProposalCommentBox from '../../proposalCommentBox/proposalCommentBox';
import ProposalsHoldingsBox from '../../proposalsHoldingsBox/proposalsHoldingsBox';
import { useWeb3React } from '@web3-react/core';
import { Rings } from 'react-loader-spinner';
import {
    truncate,
    truncateText,
    getTimeRelativeToNow,
} from '../../../utils/functions';
import useData from '../../../utils/dataContext';

const ProposalsWidget: React.FC = () => {
    const [toggle, setToggle] = useState(true);
    const {
        setUpdaoAccount,
        allPlanetBalance,
        founderDAOBalance,
        setAllPlanetBalance,
        setFounderDAOBalance,
    } = useData();
    const [myProposals, setMyProposals] = useState<Object[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>();
    const navigate = useNavigate();

    const { account, deactivate } = useWeb3React();

    const bal = (arr: any[]) => arr.filter((x) => x > 0).length >= 1;

    useEffect(() => {
        getMyProposals();
    }, []);

    const getMyProposals = () => {
        setIsLoading(true);
        let overviewParameters = {
            params: {
                pageSize: 10,
            },
        };
        axiosInstance
            .get(
                `/proposals/?authorSignerAddress=${account}`,
                overviewParameters
            )
            .then((res) => {
                const allOverviewProposals = res.data;
                setMyProposals(allOverviewProposals);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log('ERROR: ', err);
            });
    };

    async function deactivated() {
        try {
            deactivate();
            setAllPlanetBalance([]);
            setFounderDAOBalance();
            setUpdaoAccount();
            if (localStorage?.getItem('isMetamaskConnected') === 'true') {
                localStorage.setItem('isMetamaskConnected', String(false));
            }
            if (localStorage?.getItem('isCoinbaseConnected') === 'true') {
                localStorage.setItem('isCoinbaseConnected', String(false));
            }
            if (localStorage?.getItem('isConnectConnected') === 'true') {
                localStorage.setItem('isConnectConnected', String(false));
            }
            window.location.reload();
        } catch (ex) {
            console.log(ex);
        }
    }

    const DisplayProposalCreateButton = (
        isHoldingPlanets: boolean,
        isHoldingFounders: boolean
    ) => {
        return isHoldingPlanets || isHoldingFounders ? (
            <div className="create-first">
                Create your first proposal
                <button
                    className="create-proposal-button2"
                    onClick={() => navigate(`./proposals/UPDAO`)}
                >
                    <span className="create-proposal-plus">+</span> Create
                    Proposal
                </button>
            </div>
        ) : (
            <div>You do not have any holdings</div>
        );
    };

    return (
        <div className="prop-connect-card">
            <div className="walletdetails">
                <img className="prop-img" src={profile} alt="profile" />
                <div>
                    <span className="walletaddress">{truncate(account)}</span>
                    <div className="details">
                        <span
                            style={{
                                filter: 'blur(3px',
                                fontWeight: 'initial',
                                padding: '0px 0.8em 0px 0em',
                            }}
                        >
                            Score:
                        </span>
                        <span
                            style={{
                                borderLeft:
                                    '1px solid rgba(156, 156, 169, 0.74)',
                                padding: '0px 0em 0px 0.8em',
                            }}
                            onClick={deactivated}
                        >
                            Disconnect
                        </span>
                    </div>
                </div>
            </div>
            {/* Tabs */}
            <div className="tabs">
                <div className="toggle">
                    <p
                        className={`toggle ${
                            toggle ? 'proposals' : 'holdings'
                        }`}
                        onClick={() => setToggle(true)}
                    >
                        Your Proposals
                    </p>
                    <p
                        className={`toggle ${
                            !toggle ? 'proposals' : 'holdings'
                        }`}
                        onClick={() => {
                            setToggle(false);
                            getMyProposals();
                        }}
                    >
                        Your Holdings
                    </p>
                </div>

                <div className="proposals-scroll">
                    <div className="test">
                        {toggle ? (
                            <div>
                                {isLoading ? (
                                    <div className="loading-vote">
                                        <Rings
                                            height={75}
                                            color="#fff"
                                            ariaLabel="loading-indicator"
                                        />
                                        Loading proposals
                                    </div>
                                ) : (
                                    <>
                                        {myProposals.length !== 0
                                            ? myProposals.map(
                                                  (
                                                      proposal: any,
                                                      index: number
                                                  ) => (
                                                      <ProposalCommentBox
                                                          key={index}
                                                          proposalId={
                                                              proposal._id
                                                          }
                                                          planet={
                                                              proposal.planet
                                                          }
                                                          middlePadding={true}
                                                          creatorAddress={truncate(
                                                              proposal.authorSignerAddress
                                                          )}
                                                          creatorImg={profile}
                                                          createdAt={getTimeRelativeToNow(
                                                              proposal.createdAt
                                                          )}
                                                          expiresAt={getTimeRelativeToNow(
                                                              proposal.endAt
                                                          )}
                                                          title={truncateText(
                                                              proposal.title,
                                                              45
                                                          )}
                                                          // commentTotal={proposal.comments.length}
                                                          votes={proposal.votes}
                                                          status={
                                                              proposal.status
                                                          }
                                                          quorumAmount={Object.values(
                                                              proposal.updaoStatus
                                                          )}
                                                          updaoVotingTotals={
                                                              proposal.updaoVoteTotals
                                                          }
                                                          tags={proposal.tags}
                                                          showTags={false}
                                                      />
                                                  )
                                              )
                                            : DisplayProposalCreateButton(
                                                  bal(allPlanetBalance),
                                                  founderDAOBalance
                                              )}
                                    </>
                                )}
                            </div>
                        ) : (
                            <div>
                                <ProposalsHoldingsBox />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProposalsWidget;
