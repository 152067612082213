//ASSETS
import { ReactComponent as PageArrow } from "../../../images/paginationArrow.svg";
import './paginationBar.css';

interface PaginationBarProps {
    pageNumber: number,
    setPageNumber: any
}

const PaginationBar:React.FC<PaginationBarProps> = ({ pageNumber, setPageNumber }) => {

    //reminder to get BE to add a number_of_pages var so I can set max number of pages to the pagination bar
    const window_length = window.innerWidth;

    const handlePages = (newPageNumber:number) => {
        //need to update for 
        if(newPageNumber === 0){
            return
        } else{
            setPageNumber(newPageNumber)
        }
        return newPageNumber
    }

    const isActivePage = (currPageNumber:number, matchingNumber:number) => {
        if(currPageNumber !== matchingNumber){
            return "pagination-number"
        } 
        else {
            return "pagination-number-current"
        }
    }

    return (
        <div id="pagination-container">
            <button className="pagination-button" onClick={() => handlePages(pageNumber - 1)}>
                <PageArrow style={{ marginRight: '8px'}}/>
                {window_length > 600? "Prev": null}
            </button>
            <div className="pagination-numbers-container">
                <div className={isActivePage(pageNumber, 1)} onClick={() => handlePages(1)}>{1}</div>
                <div className={isActivePage(pageNumber, 2)} onClick={() => handlePages(2)}>{2}</div>
                <div className={isActivePage(pageNumber, 3)} onClick={() => handlePages(3)}>{3}</div>
                {window_length > 600
                    ?<div className={isActivePage(pageNumber, 4)} onClick={() => handlePages(4)}>{4}</div>
                    :null
                }
                {window_length > 600
                    ?<div className={isActivePage(pageNumber, 5)} onClick={() => handlePages(5)}>{5}</div>
                    :null
                }
            </div> 
            <button className="pagination-button" onClick={() => handlePages(pageNumber + 1)}>
                {window_length > 600? "Next": null}
                <PageArrow style={{ marginLeft: '8px', transform: 'rotate(180deg)' }}/>
            </button>
        </div>
        )    
}


export default PaginationBar;