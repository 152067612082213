import foundersDaoBig from '../../../images/FoundersDaoBig.webp';
import powBig from '../../../images/PowBig.webp';
import placeholder from '../../../images/placeholder.svg';
import './sectionComponent.css';
import ButtonComponent from '../buttonComponent/buttonComponent';

type SectionComponentProps = {
    section: string;
};

export const SectionComponent: React.FC<SectionComponentProps> = (props) => {
    const { section } = props;

    const foundersDao = {
        title: "Founder's DAO",
        desc: 'The Founder’s DAO was seeded with a mix of Pixel Vault assets, NFTs from other projects, and 1/1 artwork by artists such as Hackatao, Fvckrender, and Killer Acid. There are 5,902 Founder’s DAO tokens, each of which serve as one vote for DAO decisions. Tokens were created through a “burn” decision from holders of PUNKS Comic Issue #1, and that window is now closed.',
        link: 'https://pvfd.gitbook.io/founders-dao-assets/',
        imgSrc: foundersDaoBig,
    };

    const pow = {
        title: '$POW',
        desc: "The $POW tokens are in-game currency for the UPDAO's blockchain gaming ecosystem. Total $POW supply is 1,000,000,000 tokens, and it will have a non-deflationary supply. The $POW tokens are an ERC-20 token and are represented by the following address: 0x43ab765ee05075d78ad8aa79dcb1978ca3079258.",
        link: '',
        imgSrc: powBig,
    };

    const defigame = {
        title: 'Blockchain Game',
        desc: 'An immersive blockchain gaming ecosystem based on the MetaHero lore and storylines. Goal is to create an accessible and engaging experience for both existing and new members of the MetaHero Universe community. Pixel Vault is a service provider building out the first stages of the gaming ecosystem, with to-be-proposed integration with the UPDAO and PRISM.',
        link: 'https://docs.updao.app/defi-game-universe-title-tbd',
        imgSrc: placeholder,
    };

    let config;

    if (section === 'pow') {
        config = pow;
    } else if (section === 'founders') {
        config = foundersDao;
    } else {
        config = defigame;
    }

    return (
        <div className='planetary-section-component'>
            <div
                className={
                    config === defigame
                        ? 'planetary-section-container text-left'
                        : 'planetary-section-container text-right'
                }
            >
                <div className='planetary-image-container'>
                    <img src={config.imgSrc} alt={`${config.title}`} />
                </div>
                <div
                    className={
                        config === defigame
                            ? 'planetary-info-container text-left'
                            : config === pow
                            ? 'planetary-info-container text-right pow-sec'
                            : 'planetary-info-container text-right'
                    }
                >
                    <h1 className='planetary-info-title'>{config.title}</h1>
                    <p className='planetary-info-desc'>{config.desc}</p>
                    {!!config.link ? (
                    <ButtonComponent
                        target='_blank'
                        text='Learn More'
                        link={config.link}
                    />) : null }
                </div>
            </div>
        </div>
    );
};
