import { useState } from 'react';
import axiosInstance from '../../../../../axiosHelper/axios';
import { generateSignedPayload } from '../../../../../utils/functions';
import { removeSpaces } from '../../../../../utils/functions';
import ErrorComponent from '../../../../ErrorComponent/ErrorComponent';

//ASSETS
import { ReactComponent as CloseButton } from '../../../../../images/CloseButton.svg';
import creatorImg from '../../../../../images/profile.svg';
import { useWeb3React } from '@web3-react/core';
import './AddCommentModal.css';

interface ModalProps {
    account_id: string | null | undefined;
    walletAddress: string | null | undefined;
    proposal_id: string | null | undefined;
    status: boolean;
    handleCommentModal: any;
    handleCommentSubmit: any;
}

const AddCommentModal: React.FC<ModalProps> = ({
    account_id,
    proposal_id,
    status,
    walletAddress,
    handleCommentModal,
    handleCommentSubmit,
}) => {
    //State for Form
    const [comment, setComment] = useState<string>('');
    const { active, library } = useWeb3React();
    //Dummy Data
    const tempmentions = ['temp1', 'temp2'];
    //Error handling for form
    const [submissionErr, setSubmissionErr] = useState<boolean>(false);
    const [fillCommentErr, setFillCommentErr] = useState<boolean>(false);

    let provider = active ? library?.provider : undefined;

    const submitComment = async (
        author: string | null | undefined,
        comment: string,
        mentions: string[],
        proposal_id: string | null | undefined
    ) => {
        const commentNoSpace = removeSpaces(comment);
        const msgBody = {
            author: walletAddress,
            comment,
            mentions,
            proposalId: proposal_id,
            signerAddress: walletAddress,
        };
        if (commentNoSpace !== '') {
            const signature = await generateSignedPayload(provider, msgBody);
            axiosInstance
                .post('comment/create', {
                    msgBody,
                    signature,
                    author
                })
                .then((res: any) => {
                    // console.log(res);
                    handleCommentModal(status);
                    handleCommentSubmit(res.data.comment);
                    setComment('');
                    setFillCommentErr(false);
                })
                .catch((err: any) => {
                    console.error(err);
                    setSubmissionErr(true);
                });
        } else {
            setFillCommentErr(true);
            setSubmissionErr(false);
        }
    };

    if (status) {
        return (
            // <div className="create-comment-background">
            <div className="create-comment-modal">
                <div>
                    <img
                        src={creatorImg}
                        alt="profile"
                        width={40}
                        height={40}
                    />
                </div>

                <form className="create-comment-form">
                    <div className="ccf-top">
                        {/* <img className='cpf-modal-profile' src={profileImage} alt='profile'/> */}
                        <div className="cpf-account-id">{walletAddress}</div>
                        <div
                            className="cpf-close-modal cpf-x-button"
                            onClick={() => handleCommentModal(status)}
                        >
                            <CloseButton className="cpf-tag-close cpf-x-button" />
                        </div>
                    </div>
                    <textarea
                        id="ccf-description-input"
                        className="cpf-inputs"
                        placeholder="Comment..."
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    {submissionErr ? (
                        <ErrorComponent
                            errorText="There was an error submitting your comment."
                            color="#dd3434"
                        />
                    ) : null}
                    {fillCommentErr ? (
                        <ErrorComponent
                            errorText="Please fill in all sections above."
                            color="#dd3434"
                        />
                    ) : null}
                    <div className="ccf-button">
                        <div
                            className="sumbit-proposal-button button-pointer"
                            onClick={() =>
                                submitComment(
                                    account_id,
                                    comment,
                                    tempmentions,
                                    proposal_id
                                )
                            }
                        >
                            Submit Comment
                        </div>
                    </div>
                </form>
            </div>
            // </div>
        );
    } else {
        return <div style={{ display: 'none' }}></div>;
    }
};

export default AddCommentModal;
