import Select from 'react-select';
import useFilter from '../../../../utils/filterContext';

const DesktopStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: state.isFocused?"white":"white",
    background: "black",
    border: '1px solid rgba(156, 156, 169, 0.2)',
    borderRadius: '13px',
    padding: 15,
  }),
  control: ( _, { selectProps: { width }}) => ({
    display: 'flex',
    width: width,
    color: "white",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused?'rgba(231, 231, 231, 0.09)':'none',
    cursor: "pointer",
    borderRadius: "8px",
    marginBottom: '3px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const color = 'white';

    return { ...provided, opacity, transition, color };
  }
}
const MobileStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: '100%',
    color: state.isFocused?"white":"white",
    background: "black",
    padding: 15,
  }),
  menuList: (provided, state) => ({
    ...provided,
    width: '100%',
  }),
  container: (provided, state) => ({
    ...provided,
    width: '100%',
  }),
  control: ( _, { selectProps: { width }}) => ({
    display: 'flex',
    width: width,
    color: "white",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused?'rgba(231, 231, 231, 0.09)':'none',
    borderRadius: "8px",
    padding: '8%',
    marginBottom: '3px'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const color = 'white';

    return { ...provided, opacity, transition, color };
  }
}

export default function StatusFilter({ mobile }) {
  const { 
    statusOption, 
    setStatusOption, 
    filterStatusOptions
} = useFilter();
  return (
    <div className='filter-select'>
      <Select
        styles={mobile?MobileStyles:DesktopStyles}
        // className='search-bar'
        defaultValue={statusOption}
        onChange={setStatusOption}
        components={{
          IndicatorSeparator: () => null
        }}
        options={filterStatusOptions}
        isSearchable={false}
      />
    </div>
  );
}