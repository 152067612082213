import '../sectionComponent/sectionComponent.css';
import './votingMechanics.css';
import ButtonComponent from '../buttonComponent/buttonComponent';

const VotingMechanics: React.FC = () => {
    const votingDetails = [
        {
            title: 'Activation Quorum',
            desc: 'Proposals will require a 1% activation quorum of Planetary Ecosystem Tokens from each planet AND 10% of the Founder’s DAO Quorum Requirement to move an UPDAO proposal from draft to active status, placing the decision to move a proposal from draft to active in the community’s hands',
        },
        {
            title: 'Voting Quorum',
            desc: 'Once an UPDAO proposal is active, each planet will need 10% of its Planetary Ecosystem Tokens to vote, less any tokens held by Pixel Vault, to create a quorum. If the voting quorum is not reached, the planet’s UPDAO votes will not be counted towards the proposal. The Founder’s DAO Quorum Requirement must also be met in order for their UPDAO votes to count towards the proposal.',
        },
        {
            title: 'Voting Period',
            desc: 'Once a proposal is active, the voting period will be 5 days. Each planet must reach quorum before this period ends ',
        },
        {
            title: 'Proposal Approval',
            desc: 'For an UPDAO proposal to be adopted, approval must receive the largest plurality of votes, excluding any abstentions. All entities except Pixel Vault need to meet voting quorum for a proposal to be valid.',
        },
    ];

    return (
        <section
            id="voting-sec"
            className="planetary-section-component voting-container"
        >
            <div className="planetary-voting-section-container">
                <div className="planetary-info-container">
                    <div className="planetary-info-title">Voting Mechanics</div>
                    <p className="planetary-info-desc">
                    Encouraging participation is tough, and the UPDAO seeks to make governance as frictionless as possible.
                     Through PRISM, collectors are able to make governance decisions for all of their Planetary Ecosystem Tokens,
                      with a single vote. PRISM will also have social mechanics to reward governance participation over time.
                    </p>
                    <ButtonComponent
                        target="_blank"
                        text="Learn More"
                        link="https://docs.updao.app/welcome-to-the-united-planets-dao-updao/updao-voting"
                    />
                </div>
                <div className="planetary-grid-container">
                    {votingDetails.map((detail) => (
                        <div className="planetary-voting-sub">
                            <h3 className="planetary-voting-title">
                                {detail.title}
                            </h3>
                            <p className="planetary-info-desc">{detail.desc}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default VotingMechanics;
