import tempMetaHero from "../../../images/placeHolderMetaHero.webp";
import walletlink from "../../../images/walletlink.svg";

interface metahero_props {
  metahero_props: {
    id: number;
    nft_link: string;
  };
}

const cardStyle = {
  // border: '2px solid red',
  background: "rgba(44, 44, 44, 0.28)",
  width: "22%",
  border: "1px solid rgba(231, 246, 255, 0.2)",
  backdropFilter: "blur(29px)",
  borderRadius: "13px",
  marginBottom: "2%",
  marginRight: "2%",
};

const cardImageStyle = {
  borderRadius: "13px 13px 0 0",
  width: "100%",
};

const cardBottomStyle = {
  display: "flex",
  // flexDirection: "row",
  padding: "4%",
  justifyContent: "space-between",
  background: "rgba(112, 112, 124, 0.2)",
};

const NFTMetaHeroCard: React.FC<metahero_props> = ({ metahero_props }) => {
  return (
    <div style={cardStyle} className="nft-card">
      <img style={cardImageStyle} src={tempMetaHero} alt="metahero" />
      <div style={cardBottomStyle} className="nft-card-bottom">
        <div>
          <div className="nft-title">MetaHero Universe</div>
          <div className="nft-desc">MetaHero #{metahero_props.id}</div>
        </div>
        <div>
          <a href={metahero_props.nft_link}>
            View
            <img src={walletlink} className="nft-link" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default NFTMetaHeroCard;
