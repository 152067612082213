import "./ProposalsTab.css";

const ProposalsTabNavItem = ({
  id,
  title,
  activeTab,
  setActiveTab,
  setActiveTabCall,
}) => {
  const handleClick = () => {
    setActiveTab(id);
    if (title === "Closing Soon") {
      setActiveTabCall("closing");
    } else if (title === "Recently Enacted") {
      setActiveTabCall("enacted");
    } else if (title === "Pending Enactment") {
      setActiveTabCall("pending");
    } else {
      setActiveTabCall("proposals")
    }
  };

  return (
    <li
      onClick={handleClick}
      className={activeTab === id ? "tabactive" : "tabinactive"}
    >
      {title}
    </li>
  );
};
export default ProposalsTabNavItem;
