// import walletlink from "../../../images/walletlink.svg";

type coinDataProps = {
    props: {
        name: string;
        amount: string;
        dollar_amount: string;
        wallet_link: string;
        image: string;
        currency: string;
    };
};

const PortfolioCryptoCard: React.FC<coinDataProps> = ({ props }) => {
    const { name, amount, dollar_amount, wallet_link, image, currency } = props;
    return (
        <div className="portfolio-crypto-card">
            <div className="pcc-top">
                <div>
                    <img src={image} alt="token image" />
                </div>
                <h1>
                    {amount} {currency}
                </h1>
                <span>${dollar_amount} USD</span>
            </div>
            {/* <div className="pcc-bottom">
                <p>{name}</p>
                <a href={wallet_link}>
           Wallet Link
          <img src={walletlink} />
        </a>
            </div> */}
        </div>
    );
};

export default PortfolioCryptoCard;
