import React, { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import { ethers } from 'ethers';
import planetABI from './PlanetsABI.abi.json';
import founderABI from './founderDAO.abi.json';
import powABI from './powABI.abi.json';
import useData from './dataContext';
import { connectors, infuraKey } from './connectors';

const isMainnet = false;

const planetContractAddress: string = isMainnet
    ? '0x7dEB7Bce4d360Ebe68278dee6054b882aa62D19c'
    : '0xbd74656f36Fd91de7726e0cddE717ed0431Ec832';

const founderDAOContractAddress: string = isMainnet
    ? '0xd0A07a76746707f6D6d36D9d5897B14a8e9ED493'
    : '0x99Fb7310dF2F9Bba81768C38612272378091ecD3';

const powContractAddress: string = '0x43Ab765ee05075d78AD8aa79dcb1978CA3079258';

const PlanetsData: React.FC = () => {
    const { active, account, activate, library, chainId } =
        useWeb3React<Web3Provider>();
    const {
        setAllPlanetBalance,
        setFounderDAOBalance,
        setPowBalance,
        setEthBalance,
        setUpdaoAccount,
        setChainMismatch,
    } = useData();

    useEffect(() => {
        getTreasuryBalance();
    }, []);

    useEffect(() => {
        if (account) {
            getPlanetBalance(account);
        }
    }, [account]);

    let accprovider = active ? library?.provider : undefined;

    useEffect(() => {
        if (window.ethereum && window.ethereum.on) {
            const handleAccountsChanged = (accounts: string[]) => {
                if (accounts.length === 0) {
                    //locked wallet or no user accounts
                    const connectWalletOnPageLoad = async () => {
                        if (
                            localStorage?.getItem('isMetamaskConnected') ===
                            'true'
                        ) {
                            await activate(connectors.Injected);
                        } else if (
                            localStorage?.getItem('isCoinbaseConnected') ===
                            'true'
                        ) {
                            await activate(connectors.CoinbaseWallet);
                        } else if (
                            localStorage?.getItem('isConnectConnected') ===
                            'true'
                        ) {
                            await activate(connectors.WalletConnect);
                        }
                    };
                    connectWalletOnPageLoad();
                } else {
                    setAllPlanetBalance([]);
                    setFounderDAOBalance();
                    getPlanetBalance(accounts[0]);
                    setUpdaoAccount();
                    window.location.reload();
                }
            };
            window.ethereum.on('accountsChanged', handleAccountsChanged);
            window.ethereum.on('chainChanged', (chainId: any) => {
                window.location.reload();
            });
        }
    }, [accprovider]);

    useEffect(() => {
        if (isMainnet && chainId === 1) {
            setChainMismatch(false);
        } else if (!isMainnet && chainId === 5) {
            setChainMismatch(false);
        } else {
            setChainMismatch(true);
        }
    }, [chainId]);

    const mainnetNetwork = {
        chainId: 1,
        apiKey: infuraKey,
        etherscanApiKey: 'JWA5EPQIPCH5H4YARZYSVMG7A2EK53R9WB',
        nodeURL:
            'https://mainnet.infura.io/v3/9efc8827d1694b21bbf01cb03097b2d1',
        name: 'homestead',
    };

    const provider = new ethers.providers.InfuraProvider(
        mainnetNetwork.name,
        mainnetNetwork.apiKey
    );

    const getTreasuryBalance = async () => {
        try {
            const updaoWallet: string =
                '0x19C30Ad5EA4f7f9F36A8662b5FA2Cbc09E55FDED';

            const powContract = new Contract(
                powContractAddress,
                powABI,
                provider
            );

            const powCurrBalance = await powContract.balanceOf(updaoWallet);
            const powNum = parseInt(powCurrBalance._hex);
            setPowBalance(powNum / Math.pow(10, 18));

            await provider.getBalance(updaoWallet).then((balance) => {
                const ethCurrBalance = ethers.utils.formatEther(balance);

                setEthBalance(ethCurrBalance);
            });
        } catch (error) {
            console.log('Error: ', error);
        }
    };

    const getPlanetBalance = async (account: string | null | undefined) => {
        let owner: any;
        try {
            //Planets
            const contract = new Contract(
                planetContractAddress,
                planetABI,
                library?.getSigner()
            );

            //Founder's DAO
            const founderContract = new Contract(
                founderDAOContractAddress,
                founderABI,
                library?.getSigner()
            );

            if (account) {
                owner = account;

                //balanceOfBatch
                const ownerAll: any[] = new Array(10).fill(owner);
                const tokenIdAll: any[] = [0, 1, 2, 10, 4, 5, 6, 7, 8, 9];

                const allBalances = await contract.balanceOfBatch(
                    ownerAll,
                    tokenIdAll
                );

                const mapAllbalances = allBalances.map(
                    (bal: { toNumber: () => any }) => bal.toNumber()
                );

                setAllPlanetBalance(mapAllbalances);

                //balanceOf
                const balance = await founderContract.balanceOf(owner);
                let balanced = balance.toNumber();
                setFounderDAOBalance(balanced);
            }
        } catch (error: any) {
            console.log(error);
        }
    };

    return <></>;
};

export default PlanetsData;
