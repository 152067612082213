import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import Header from "./components/headerComponent/headerComponent";
import Overview from "./components/OverviewSection/overviewDashboard/overviewDashboard";
import PlanetaryEcosystem from "./components/PlanetaryEcoSection/planetaryEcosystem";
import useData from "./utils/dataContext";
import TreasurySection from "./components/TreasurySection/sideBar/TreasurySection";
import ProposalSection from "./components/ProposalsSection/sideBar/proposalSection";
import PlanetsData from "./utils/allplanetaryData";
import { Footer } from "./components/Footer/footer";
import { checkSuspiciousWallet } from "./utils/checkSuspiciousWallet";
import SuspiciousWallet from "./components/SuspiciousWallet";

//ASSETS
import defaultbg from "./images/default-bg.webp";
import mercurybg from "./images/mercurybg.webp";
import venusbg from "./images/venusbg.webp";
import earthbg from "./images/earthbg.webp";
import moonbg from "./images/moonbg.webp";
import marsbg from "./images/marsbg.webp";
import jupiterbg from "./images/jupiterbg.webp";
import saturnbg from "./images/saturnbg.webp";
import uranusbg from "./images/uranusbg.webp";
import neptunebg from "./images/neptunebg.webp";
import plutobg from "./images/plutobg.webp";
import "./App.css";

const planetNames = [
  "UPDAO",
  "Mercury",
  "Venus",
  "Earth",
  "Moon",
  "Mars",
  "Jupiter",
  "Saturn",
  "Uranus",
  "Neptune",
  "Pluto",
];

const App: React.FC = () => {
  const { active, account } = useWeb3React<Web3Provider>();
  const [backgroundImages, setBackgroundImages] = useState<string>(defaultbg);
  const { setPlanetsNames, walletSus, setWalletSus } = useData();
  const location = useLocation();

  useEffect(() => {
    let realPlanetName: string = "";
    let planetlocation = location.pathname.slice(11);
    let treasurylocation = location.pathname.slice(10);

    planetNames.forEach((planet) => {
      if (planetlocation.includes(planet)) {
        realPlanetName = planet;
      } else if (treasurylocation.includes(planet)) {
        realPlanetName = planet;
      }
    });

    setPlanetsNames(realPlanetName);
    switch (realPlanetName) {
      case "UPDAO":
        setBackgroundImages(defaultbg);
        break;
      case "Mercury":
        setBackgroundImages(mercurybg);
        break;
      case "Venus":
        setBackgroundImages(venusbg);
        break;
      case "Earth":
        setBackgroundImages(earthbg);
        break;
      case "Moon":
        setBackgroundImages(moonbg);
        break;
      case "Mars":
        setBackgroundImages(marsbg);
        break;
      case "Jupiter":
        setBackgroundImages(jupiterbg);
        break;
      case "Saturn":
        setBackgroundImages(saturnbg);
        break;
      case "Uranus":
        setBackgroundImages(uranusbg);
        break;
      case "Neptune":
        setBackgroundImages(neptunebg);
        break;
      case "Pluto":
        setBackgroundImages(plutobg);
        break;
      default:
        setBackgroundImages(defaultbg);
        break;
    }
  }, [location, setPlanetsNames]);

  async function walletAuth() {
    const auth = await checkSuspiciousWallet(account);
    setWalletSus(auth)
  
}
  useEffect(() => {
    if(active){
      walletAuth()
    }
  }, [active])

    return (
        <div
            className="App"
            style={{
                backgroundImage: `url(${backgroundImages})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundAttachment: "fixed",
                transition: "background-image 0.5s ease",
            }}
        >
            <PlanetsData />
            <Header />
            <Routes>
                <Route path="/suspicious" element={<SuspiciousWallet />} />
                <Route path="/proposals/*" element={
                  <PrivateWrapper walletSus={walletSus}>
                    <ProposalSection />
                  </PrivateWrapper>
                  } 
                  />
                <Route path="/treasury" element={<TreasurySection />} />
                <Route
                    path="/planetary-ecosystem"
                    element={<PlanetaryEcosystem />}
                />
                <Route path="/" element={
                 <PrivateWrapper walletSus={walletSus}>
                  <Overview />
                 </PrivateWrapper>
                } />
            </Routes>

      <Footer
        planetEco={location.pathname === "/planetary-ecosystem" ? true : false}
      />
    </div>
  );
};

export default App;

const PrivateWrapper = ({ children, walletSus} : { children: JSX.Element, walletSus: any }) => {
  return walletSus ? <Navigate to="/suspicious" /> : children
};