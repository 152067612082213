import InfoRow from '../infoRow/infoRow';
import './genericInfoComponent.css';
import { useEffect, useState } from 'react';
import { parseISO } from 'date-fns';

interface StatusProp {
    status: string;
    starts: string;
    ends: string;
    blockNumber: number;
}

export default function GenericInfoComponent(props: StatusProp) {
    const { status, ends, starts, blockNumber } = props;
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const monthsNum = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    function formatAMPM(date: any) {
        let year = date.getFullYear();
        let month = date.getMonth();
        let days = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime =
            monthsNum[month] +
            ' ' +
            days +
            ', ' +
            year +
            ' ' +
            hours +
            ':' +
            minutes +
            ' ' +
            ampm;
        return strTime;
    }

    useEffect(() => {
        // if (status === "Active") {
        setStartDate(formatAMPM(parseISO(starts)));
        setEndDate(formatAMPM(parseISO(ends)));
        // }
    }, [status]);

    // console.log(formatAMPM(new Date()));

    const mockInfo = {
        'Start Date': startDate,
        'End Date': endDate,
        'Creation Block Number': blockNumber,
    };
    return (
        <>
            <div className="info-container">
                <h2>Information</h2>
                <ul style={{ paddingTop: '20px' }}>
                    <InfoRow details={mockInfo} />
                </ul>
            </div>
        </>
    );
}
