import { QueryClient, QueryClientProvider } from "react-query";
import "./metaheroPriceGraph.css";
import PowTracker from "./powTracker";

const queryClient = new QueryClient();


const MetaheroPriceGraph = () => (
  <QueryClientProvider client={queryClient}>
    <PowTracker />
  </QueryClientProvider>
);

export default MetaheroPriceGraph;
