import mercury from "../../../images/planet-mercury.svg";
import venus from "../../../images/planet-venus.svg";
import earth from "../../../images/planet-earth.svg";
import moon from "../../../images/planet-moon.svg";
import mars from "../../../images/planet-mars.svg";
import jupiter from "../../../images/planet-jupiter.svg";
import saturn from "../../../images/planet-saturn.svg";
import uranus from "../../../images/planet-uranus.svg";
import neptune from "../../../images/planet-neptune.svg";
import pluto from "../../../images/planet-pluto.svg";
import useData from "../../../utils/dataContext";
import "./planetsGrid.css";
import ButtonComponent from "../buttonComponent/buttonComponent";

const PlanetsGrid: React.FC = () => {
    const { allPlanetWeights } = useData();
    const planetEcoData = [
        {
            planetName: "Mercury",
            tokens: allPlanetWeights.updao?.["Mercury"]?.["circulating"].toLocaleString(
                "en-US"
            ),
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequatur similique fuga porro. Voluptate tempore earum veritatin",
            osLink: "https://opensea.io/assets/ethereum/0x7deb7bce4d360ebe68278dee6054b882aa62d19c/0",
            lrLink: "https://looksrare.org/collections/0x7dEB7Bce4d360Ebe68278dee6054b882aa62D19c/0",
            imgSrc: mercury,
            width: "85px",
            height: "85px",
        },
        {
            planetName: "Venus",
            tokens: allPlanetWeights.updao?.["Venus"]?.["circulating"].toLocaleString(
                "en-US"
            ),
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequatur similique fuga porro. Voluptate tempore earum veritatin",
            osLink: "https://opensea.io/assets/ethereum/0x7deb7bce4d360ebe68278dee6054b882aa62d19c/1",
            lrLink: "https://looksrare.org/collections/0x7dEB7Bce4d360Ebe68278dee6054b882aa62D19c/1",
            imgSrc: venus,
            width: "140px",
            height: "140px",
        },
        {
            planetName: "Earth",
            tokens: allPlanetWeights.updao?.["Earth"]?.["circulating"].toLocaleString(
                "en-US"
            ),
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequatur similique fuga porro. Voluptate tempore earum veritatin",
            osLink: "https://opensea.io/assets/ethereum/0x7deb7bce4d360ebe68278dee6054b882aa62d19c/2",
            lrLink: "https://looksrare.org/collections/0x7dEB7Bce4d360Ebe68278dee6054b882aa62D19c/2",
            imgSrc: earth,
            width: "134px",
            height: "134px",
        },
        {
            planetName: "Moon",
            tokens: allPlanetWeights.updao?.["Moon"]?.["circulating"].toLocaleString(
                "en-US"
            ),
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequatur similique fuga porro. Voluptate tempore earum veritatin",
            osLink: "https://opensea.io/assets/ethereum/0x7deb7bce4d360ebe68278dee6054b882aa62d19c/10",
            lrLink: "https://looksrare.org/collections/0x7dEB7Bce4d360Ebe68278dee6054b882aa62D19c/10",
            imgSrc: moon,
            width: "97px",
            height: "97px",
        },
        {
            planetName: "Mars",
            tokens: allPlanetWeights.updao?.["Mars"]?.["circulating"].toLocaleString(
                "en-US"
            ),
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequatur similique fuga porro. Voluptate tempore earum veritatin",
            osLink: "https://opensea.io/assets/ethereum/0x7deb7bce4d360ebe68278dee6054b882aa62d19c/4",
            lrLink: "https://looksrare.org/collections/0x7dEB7Bce4d360Ebe68278dee6054b882aa62D19c/4",
            imgSrc: mars,
            width: "116px",
            height: "116px",
        },
        {
            planetName: "Jupiter",
            tokens: allPlanetWeights.updao?.["Jupiter"]?.["circulating"].toLocaleString(
                "en-US"
            ),
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequatur similique fuga porro. Voluptate tempore earum veritatin",
            osLink: "https://opensea.io/assets/ethereum/0x7deb7bce4d360ebe68278dee6054b882aa62d19c/5",
            lrLink: "https://looksrare.org/collections/0x7dEB7Bce4d360Ebe68278dee6054b882aa62D19c/5",
            imgSrc: jupiter,
            width: "150px",
            height: "150px",
        },
        {
            planetName: "Saturn",
            tokens: allPlanetWeights.updao?.["Saturn"]?.["circulating"].toLocaleString(
                "en-US"
            ),
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequatur similique fuga porro. Voluptate tempore earum veritatin",
            osLink: "https://opensea.io/assets/ethereum/0x7deb7bce4d360ebe68278dee6054b882aa62d19c/6",
            lrLink: "https://looksrare.org/collections/0x7dEB7Bce4d360Ebe68278dee6054b882aa62D19c/6",
            imgSrc: saturn,
            width: "138px",
            height: "138px",
        },
        {
            planetName: "Uranus",
            tokens: allPlanetWeights.updao?.["Uranus"]?.["circulating"].toLocaleString(
                "en-US"
            ),
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequatur similique fuga porro. Voluptate tempore earum veritatin",
            osLink: "https://opensea.io/assets/ethereum/0x7deb7bce4d360ebe68278dee6054b882aa62d19c/7",
            lrLink: "https://looksrare.org/collections/0x7dEB7Bce4d360Ebe68278dee6054b882aa62D19c/7",
            imgSrc: uranus,
            width: "134px",
            height: "134px",
        },
        {
            planetName: "Neptune",
            tokens: allPlanetWeights.updao?.["Neptune"]?.["circulating"].toLocaleString(
                "en-US"
            ),
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequatur similique fuga porro. Voluptate tempore earum veritatin",
            osLink: "https://opensea.io/assets/ethereum/0x7deb7bce4d360ebe68278dee6054b882aa62d19c/8",
            lrLink: "https://looksrare.org/collections/0x7dEB7Bce4d360Ebe68278dee6054b882aa62D19c/8",
            imgSrc: neptune,
            width: "112px",
            height: "112px",
        },
        {
            planetName: "Pluto",
            tokens: allPlanetWeights.updao?.["Pluto"]?.["circulating"].toLocaleString(
                "en-US"
            ),
            desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequatur similique fuga porro. Voluptate tempore earum veritatin",
            osLink: "https://opensea.io/assets/ethereum/0x7deb7bce4d360ebe68278dee6054b882aa62d19c/9",
            lrLink: "https://looksrare.org/collections/0x7dEB7Bce4d360Ebe68278dee6054b882aa62D19c/9",
            imgSrc: pluto,
            width: "98px",
            height: "98px",
        },
    ];

    return (
        <section className="planet-section">
            <div className="main-planet-info">
                <h1 className="main-title">Planets</h1>
                <p className="main-sub-text">
                One of 10 tokens that represent the nine planets and Earth's moon in the Inhabitants Universe.
                A full “Planet Set” includes all nine planet tokens + a moon token. Each token also represents
                 one vote in the UPDAO. Dark Moon IS NOT a Planet token.
                </p>

                <ButtonComponent
                    target="_blank"
                    text="Learn More"
                    link="https://docs.updao.app/proposal-processes/planet-proposal-process"
                />
            </div>
            <div className="planet-grid">
                {planetEcoData.map((planet) => (
                    <div className="planet-container">
                        <div
                            className="planet-img-container"
                            style={{
                                backgroundImage: `url(${planet.imgSrc})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center",
                            }}
                        >
                            {/* <img
                style={{
                  width: planet.width,
                  height: planet.height,
                }}
                src={planet.imgSrc}
                alt={`${planet.planetName} image`}
              /> */}
                        </div>
                        <div className="card-content">
                            <h3 className="planet-title">{planet.planetName}</h3>
                            <span className="planet-tokens">
                                {planet.tokens} Tokens
                            </span>
                            <p className="planet-desc">{}</p>
                            <div className="btn-container">
                                <ButtonComponent
                                    target="_blank"
                                    text="Opensea"
                                    link={planet.osLink}
                                />
                                <ButtonComponent
                                    target="_blank"
                                    text="LooksRare"
                                    link={planet.lrLink}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default PlanetsGrid;
