import * as React from 'react';
import { useEffect, useState } from 'react';
import axiosInstance from '../axiosHelper/axios';

//CONTAINS STATE FOR FILTER AND PAGINATION

type FilterType = {
    value: string,
    label: string
}

const FilterContext = React.createContext<any>(null);

function useFilter() {
    const filterProposalOptions = [
        { value: 'endAt', label: 'Closing Soon' },
        { value: 'createdAt', label: 'Most Recent' },
    ];

    const filterStatusOptions = [
        { value: 'All', label: 'All' },
        { value: 'Active', label: 'Active' },
        { value: 'Quorum', label: 'Quorum' },
        { value: 'Draft', label: 'Draft' },
        { value: 'Closed', label: 'Closed' },
    ];

    const filterTagOptions = [{ value: 'All', label: 'All Tags' }];   

    const [statusOption, setStatusOption] = useState<FilterType>(filterStatusOptions[0]);
    const [proposalOption, setProposalOption] = useState<FilterType>(filterProposalOptions[0]);
    const [tagOption, setTagOption] = useState<FilterType>(filterTagOptions[0]);
    const [tagOptions, setTagOptions] = useState<any>([]);

    const [pageNumber, setPageNumber] = useState<number>(1);

    const getAvailableTags = () => {
        axiosInstance
            .get('proposals/tags')
            .then((res) => {
                let availableTags = res.data;
                let collectedTags = [];
                for (let i = 0; i < availableTags.length; i++) {
                    collectedTags.push({
                        value: availableTags[i],
                        label: availableTags[i],
                    });
                }
                setTagOptions(collectedTags);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getAvailableTags();
    }, []);

    return {
        filterStatusOptions,
        filterProposalOptions,
        tagOptions,
        statusOption,
        setStatusOption,
        proposalOption,
        setProposalOption,
        tagOption,
        setTagOption,
        getAvailableTags,
        pageNumber,
        setPageNumber
    }
}

export const FilterProvider: React.FC<any> = ({ children }) => {
    const filter = useFilter();

    return <FilterContext.Provider value={filter}>{children}</FilterContext.Provider>;
};

export default function FilterConsumer() {
    return React.useContext(FilterContext);
}