import axios from "axios";

export const checkSuspiciousWallet = async ( walletaddress: any) => {
    let rating = await axios
        .get(`https://punkscomic.com/audit-wallet.php?addr=${walletaddress}`)
        .then(function (response) {
            return response.data[0].rating
        }).catch((err) => {
            console.log(err);
        });;
    if(rating === 'unknown' || rating === 'lowRisk') return false
    return true
} 