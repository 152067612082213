import React, { useState, useEffect } from "react";
import updao from "../../images/updao.svg";
import mercury from "../../images/Mercury.svg";
import venuslight from "../../images/venuslight.svg";
import earth from "../../images/Earth.svg";
import moon from "../../images/Moon.svg";
import marslight from "../../images/marslight.svg";
import jupiterlight from "../../images/jupiterlight.svg";
import saturnlight from "../../images/saturnlight.svg";
import uranus from "../../images/Uranus.svg";
import neptune from "../../images/Neptune.svg";
import pluto from "../../images/Pluto.svg";
import useData from "../../utils/dataContext";

export const PlanetImage: React.FC = () => {
  const [planetImages, setPlanetImages] = useState<string>(updao);
  const { planetsNames } = useData();

  useEffect(() => {
    switch (planetsNames) {
      case "UPDAO":
        setPlanetImages(updao);
        break;
      case "Mercury":
        setPlanetImages(mercury);
        break;
      case "Venus":
        setPlanetImages(venuslight);
        break;
      case "Earth":
        setPlanetImages(earth);
        break;
      case "Moon":
        setPlanetImages(moon);
        break;
      case "Mars":
        setPlanetImages(marslight);
        break;
      case "Jupiter":
        setPlanetImages(jupiterlight);
        break;
      case "Saturn":
        setPlanetImages(saturnlight);
        break;
      case "Uranus":
        setPlanetImages(uranus);
        break;
      case "Neptune":
        setPlanetImages(neptune);
        break;
      case "Pluto":
        setPlanetImages(pluto);
        break;
      default:
        setPlanetImages(updao);
        break;
    }
  }, [planetsNames]);

  return (
    <img
      className="planets-logo"
      width="37"
      height="37"
      alt="Network"
      src={planetImages}
    />
  );
};
