import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { truncateText } from '../../utils/functions';
import axiosInstance from '../../axiosHelper/axios';

import './proposalCommentBox.css';
import chat from '../../images/chat.svg';
import thumbsup from '../../images/Thumbup.svg';
import thumbsdown from '../../images/Thumbdown.svg';
import abstain from '../../images/abstained.svg';
import { ReactComponent as Circle } from '../../images/Circle.svg';
import mercury from '../../images/Mercury.svg';
import venus from '../../images/venuslight.svg';
import earth from '../../images/Earth.svg';
import moon from '../../images/Moon.svg';
import mars from '../../images/marslight.svg';
import jupiter from '../../images/jupiterlight.svg';
import saturn from '../../images/saturnlight.svg';
import uranus from '../../images/Uranus.svg';
import neptune from '../../images/Neptune.svg';
import pluto from '../../images/Pluto.svg';
import updao from '../../images/updao.svg';
import { truncate } from '../../utils/functions';

type CommentBoxProps = {
    creatorAddress: string;
    creatorImg: string;
    createdAt: string; // string, might change to Date later
    expiresAt: string; // string, might change to Date later
    title: string;
    // commentTotal: number;
    tags?: any[]; // Tag[] unclear what Tag type will be specifically, but an object with one key "name" is sufficient for now
    votes: any; // ProposalVote : { option1Count: int, option2Count: int }
    middlePadding: boolean;
    proposalId: string;
    planet: string;
    status: string;
    quorumAmount?: any[];
    updaoVotingTotals?: any;
    showTags?: boolean;
};

const ProposalCommentBox: React.FC<CommentBoxProps> = (props) => {
    const {
        middlePadding,
        creatorAddress,
        creatorImg,
        createdAt,
        expiresAt,
        title,
        // commentTotal,
        proposalId,
        planet,
        tags,
        votes,
        status,
        quorumAmount,
        updaoVotingTotals,
        showTags,
    } = props;
    const windowWidth = window.innerWidth;
    const navigate = useNavigate();
    const [commentTotal, setCommentTotal] = useState<number>(0);
    const [yesVotes, setYesVotes] = useState(0);
    const [noVotes, setNoVotes] = useState(0);
    const [abstainVotes, setAbstainVotes] = useState(0);
    const [ensProfileUrl, setEnsProfileUrl] = useState<string>(creatorImg);
    let yesUpdaoTemp = 0;
    let noUpdaoTemp = 0;
    let abstainUpdaoTemp = 0;
    let updaoToWin = 97;
    let totalUpdaoVotes = yesUpdaoTemp + noUpdaoTemp + abstainUpdaoTemp;
    let amount = 0;
    let votesAreIn = false;
    let metQuorum = quorumAmount?.every((el) => el === 'Quorum');

    const planetsInfo = [
        { planetName: 'Mercury', updaoVotes: 5, imgSrc: mercury },
        { planetName: 'Venus', updaoVotes: 9, imgSrc: venus },
        { planetName: 'Earth', updaoVotes: 8, imgSrc: earth },
        { planetName: 'Moon', updaoVotes: 12, imgSrc: moon },
        { planetName: 'Mars', updaoVotes: 3, imgSrc: mars },
        { planetName: 'Jupiter', updaoVotes: 20, imgSrc: jupiter },
        { planetName: 'Saturn', updaoVotes: 17, imgSrc: saturn },
        { planetName: 'Uranus', updaoVotes: 6, imgSrc: uranus },
        { planetName: 'Neptune', updaoVotes: 6, imgSrc: neptune },
        { planetName: 'Pluto', updaoVotes: 1, imgSrc: pluto },
        { planetName: 'UPDAO', updaoVotes: 0, imgSrc: updao },
        { planetName: 'FoundersDAO', updaoVotes: 19 },
        // { planetName: 'pixelvault', updaoVotes: 87 },
    ];

    useEffect(() => {
        if (votesAreIn) return;
        if (planet === 'UPDAO') {
            getUpdaoVoteTotals();
            setYesVotes(yesUpdaoTemp);
            setNoVotes(noUpdaoTemp);
            setAbstainVotes(abstainUpdaoTemp);
            votesAreIn = true;
            if (totalUpdaoVotes >= 97) {
                updaoToWin = 0;
            } else {
                updaoToWin = updaoToWin - totalUpdaoVotes;
            }
        } else {
            setYesVotes(votes.yes || 0);
            setAbstainVotes(votes.abstain || 0);
            setNoVotes(votes.no || 0);
            votesAreIn = true;
        }
    }, [updaoVotingTotals]);

    useEffect(() => {
        getCommentTotals(proposalId);
    }, [proposalId]);

    useEffect(() => {
        console.log(
            'creator address:', creatorAddress
        )
        const getEnsProfile = async (walletAddress: string) => {
            await axiosInstance
                .get(`/accounts/ens/?address=${walletAddress}`)
                .then((res) => {
                    if(res.data){
                        let ensUrl = res.data.ensInfo.ensInfo.avatar.linkage[7].content
                        setEnsProfileUrl(ensUrl)
                    }
                })
                .catch((err) => {
                    console.log(`Could not find ens profile: ${err}`)
                })
        }
        getEnsProfile(creatorAddress);
    }, [creatorAddress]);

    const getCommentTotals = (proposalId: string) => {
        let queryparams = { params: { proposalId: proposalId } };
        axiosInstance
            .get('/comment/totals/', queryparams)
            .then((res) => {
                const totalComments = res.data;
                setCommentTotal(totalComments);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // Check vote for totals
    let planetsYes = (planet: string) => updaoVotingTotals?.[planet]?.['yes'];

    let planetsNo = (planet: string) => updaoVotingTotals?.[planet]?.['no'];

    let planetsAbstain = (planet: string) =>
        updaoVotingTotals?.[planet]?.['abstain'];

    const getUpdaoVoteTotals = () => {
        if (status === 'Quorum' || status === 'Closed') {
            for (let i = 0; i < planetsInfo.length; i++) {
                let planet = planetsInfo[i].planetName;
                let planetsUpdaoVotes = planetsInfo[i].updaoVotes;
                if (
                    planetsYes(planet) > planetsNo(planet) &&
                    planetsYes(planet) > planetsAbstain(planet)
                ) {
                    yesUpdaoTemp += planetsUpdaoVotes;
                } else if (
                    planetsNo(planet) > planetsYes(planet) &&
                    planetsNo(planet) > planetsAbstain(planet)
                ) {
                    noUpdaoTemp += planetsUpdaoVotes;
                } else if (
                    planetsAbstain(planet) > planetsYes(planet) &&
                    planetsAbstain(planet) > planetsNo(planet)
                ) {
                    abstainUpdaoTemp += planetsUpdaoVotes;
                } else {
                    if (
                        planetsYes(planet) > planetsNo(planet) &&
                        planetsYes(planet) === planetsAbstain(planet)
                    ) {
                        yesUpdaoTemp += planetsUpdaoVotes;
                    }
                    // NO > YES && NO == ABSTAIN
                    else if (
                        planetsNo(planet) > planetsYes(planet) &&
                        planetsNo(planet) === planetsAbstain(planet)
                    ) {
                        noUpdaoTemp += planetsUpdaoVotes;
                    }
                    // YES == NO == ABSTAIN
                    else if (
                        planetsYes(planet) === planetsNo(planet) &&
                        planetsYes(planet) === planetsAbstain(planet)
                    ) {
                        noUpdaoTemp += planetsUpdaoVotes;
                    } else {
                        noUpdaoTemp += planetsUpdaoVotes;
                    }
                }
            }
        }
    };

    const renderPlanetImage: any = (name: string) => {
        let planetImg;
        planetsInfo.map((planet) => {
            if (name === planet.planetName) {
                planetImg = planet.imgSrc;
            }
        });
        return planetImg;
    };

    const renderColor = (status: string) => {
        switch (status) {
            case 'Closed':
                return '#DD3434';
            case 'Active':
                return '#42DD34';
            case 'Quorum':
                return '#0EA8FF';
            default:
                return '#F8B83C';
        }
    };

    const getVoteState = () => {
        if (
            planet === 'UPDAO' &&
            (status === 'Draft' ||
                status === 'Active' ||
                (!metQuorum && status === 'Closed'))
        ) {
            return (
                <span>
                    {amount}/11 Reached{' '}
                    {status === 'Draft' ? 'Activation' : 'Voting'} Quorum
                </span>
            );
        } else {
            return (
                <div className="overview-vote-cont">
                    <div>
                        <img
                            className="prop-img2"
                            src={thumbsup}
                            alt="vote up"
                            width={15}
                            height={15}
                        />
                        {planet === 'UPDAO' && status === 'Closed'
                            ? updaoVotingTotals?.['yes']
                            : yesVotes}
                    </div>
                    <div>
                        <img
                            className="prop-img2"
                            src={abstain}
                            alt="vote abstain"
                            width={15}
                            height={15}
                        />
                        {planet === 'UPDAO' && status === 'Closed'
                            ? updaoVotingTotals?.['abstain']
                            : abstainVotes}
                    </div>
                    <div>
                        <img
                            className="prop-img2"
                            src={thumbsdown}
                            alt="vote down"
                            width={15}
                            height={15}
                        />
                        {planet === 'UPDAO' && status === 'Closed'
                            ? updaoVotingTotals?.['no']
                            : noVotes}
                    </div>
                </div>
            );
        }
    };

    quorumAmount?.forEach((reached) => {
        if (status === 'Closed') {
            if (reached === 'Quorum') {
                amount += 1;
            }
        } else {
            let relevantStatus = status === 'Draft' ? 'Active' : 'Quorum';
            if (reached === relevantStatus) {
                amount += 1;
            } else {
                amount += 0;
            }
        }
    });

    return (
        <>
            <div
                className="comment-card"
                onClick={() => navigate(`./proposals/${planet}/${proposalId}`)}
            >
                <div className="overview-flex-container">
                    <div
                        className={
                            middlePadding
                                ? 'flex-middleitem-left'
                                : 'flex-item-left'
                        }
                    >
                        <div className="details-container overview">
                            <img
                                className="prop-img2"
                                src={ensProfileUrl}
                                alt="profile"
                                width={18}
                                height={18}
                            />
                            <span className="address">{truncate(creatorAddress)}</span>
                            <span className="other-span">{createdAt}</span>
                        </div>
                    </div>
                    <div
                        className={
                            middlePadding
                                ? 'flex-middleitem-right'
                                : 'flex-item-right'
                        }
                    >
                        <div className="details-container overview">
                            <span className="address">{expiresAt}</span>
                            <div className="active-status">
                                <Circle fill={renderColor(status)} />
                                <span className="other-span">{status}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="prop-text">
                    <p>{title}</p>
                </div>

                <div className="overview-flex-container">
                    <div
                        className={
                            middlePadding
                                ? 'flex-middleitem-left-second'
                                : 'flex-item-left-second'
                        }
                    >
                        <div className="details-container overview details-contd">
                            <img
                                className="prop-img2"
                                src={chat}
                                alt="profile"
                                width={14}
                                height={14}
                            />
                            <span
                                style={{ marginLeft: '5px' }}
                                className="address1"
                            >
                                {commentTotal}
                            </span>
                            <span className="proposals-planet">
                                <img
                                    width={18}
                                    height={18}
                                    src={renderPlanetImage(planet)}
                                    alt=""
                                />
                                {planet}
                            </span>
                            {!showTags && showTags !== undefined ? null : (
                                <>
                                    <div className="tags-cont">
                                        {windowWidth > 1560 ? (
                                            tags?.map((tag) => (
                                                <span className="addressd">
                                                    {tags.length > 2
                                                        ? truncateText(tag, 4)
                                                        : tag}
                                                </span>
                                            ))
                                        ) : (
                                            <>
                                                {tags?.[0] ? (
                                                    <span className="addressd">
                                                        {truncateText(
                                                            tags?.[0],
                                                            10
                                                        )}
                                                    </span>
                                                ) : null}
                                                {tags?.[1] ? (
                                                    <span className="addressd">
                                                        {truncateText(
                                                            tags?.[1],
                                                            10
                                                        )}
                                                    </span>
                                                ) : null}
                                                {tags?.[2] ? (
                                                    <span className="addressd">
                                                        +{tags.length - 2}
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div
                        className={
                            middlePadding
                                ? 'flex-middleitem-right-second'
                                : 'flex-item-right-second'
                        }
                    >
                        <div className="details-container overview details-contd">
                            {/* replace this with a react component or react package */}
                            {getVoteState()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProposalCommentBox;
